import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {EventService} from "@services/event.service";
import {Event} from "@models/event";
import {ToastService} from "@services/toast.service";
import {PrizeService} from "@services/prize.service";
import {Prize} from "@models/prize";
import {combineLatest, defaultIfEmpty} from "rxjs";
import {CardsLoadingComponent} from "@components/front/cards-loading/cards-loading.component";


@Component({
    selector: 'app-prize-library',
    templateUrl: './library.component.html',
    styleUrls: ['./library.component.css'],
    standalone: true,
    imports: [
        CardsLoadingComponent
    ]
})
export class LibraryComponent {
    @ViewChild('cancelBtn') cancelBtn: ElementRef;

    private selectedPrizes: Prize[] = [];

    saving = false;
    event: Event;
    prizes?: Prize[];

    constructor(
        private route: ActivatedRoute,
        private eventService: EventService,
        private prizeService: PrizeService,
        private toast: ToastService,
        private router: Router,
    ) {
        this.route.queryParamMap.subscribe((params: ParamMap) => {
            const eventId = Number(params.get('eventId'));
            this.eventService.get(eventId).subscribe((event) => this.event = event);

            const prizesFromClient$ = this.prizeService.getPrizesFromCurrentClient().pipe(
                defaultIfEmpty([])
            );
            const prizeList$ = this.prizeService.getList(eventId);

            combineLatest([prizesFromClient$, prizeList$]).subscribe(([prizes, prizeList]) => {
                this.prizes = prizes;

                const selectedPrizesIds = prizeList.map((a) => a.id);
                this.selectedPrizes = prizes.filter((a) => selectedPrizesIds.includes(a.id));
            });
        });
    }

    toggleSelect(prize: Prize) {
        if (this.isSelected(prize)) {
            this.selectedPrizes = this.selectedPrizes.filter((a) => a.id !== prize.id);
            return;
        }

        if (this.selectedPrizes.length === 2) {
            this.toast.warn($localize`You can't select more than 2 prizes`);
            return;
        }

        this.selectedPrizes.push(prize);
    }

    edit(prize: Prize) {
        this.cancelBtn.nativeElement.click();
        this.router.navigate(['events', this.event.id, 'prizes', prize.id, 'edit']);
    }

    isSelected(prize: Prize) {
        return this.selectedPrizes.includes(prize);
    }

    saveToEvent(): void {
        this.saving = true;
        this.prizeService.saveList(this.event.id, this.selectedPrizes).subscribe(() => {
            this.toast.success($localize`Action list saved`);
            this.cancelBtn.nativeElement.click();
        });
    }
}
