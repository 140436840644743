<app-header></app-header>

<main class="container">
    <h1 class="my-4" i18n>Statistics for {{ clientName }}</h1>

    <div class="alert d-flex alert-warning alert-dismissible fade show" role="alert">
        <i class="bx bx-error lead me-3"></i>
        <div>
            <ng-container i18n>We are currently working on this page. Some data may be incorrect or missing.
            </ng-container>
            <br>
            <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>

    <div>
        <ul class="nav nav-tabs nav-fill d-flex" role="tablist">
            <li class="nav-item flex-grow-1">
                <a class="nav-link justify-content-center active" data-bs-toggle="tab" data-bs-target="#home"
                   role="tab">
                    General
                </a>
            </li>
            <li class="nav-item flex-grow-1">
                <a class="nav-link justify-content-center" data-bs-toggle="tab" data-bs-target="#time_action"
                   role="tab" i18n>
                    Time spent
                </a>
            </li>
            <li class="nav-item flex-grow-1">
                <a class="nav-link justify-content-center" data-bs-toggle="tab" data-bs-target="#amount_action"
                   role="tab" i18n>
                    Amount of actions
                </a>
            </li>
        </ul>
    </div>

    <div class="tab-content">
        <div class="tab-pane fade show active" id="home" role="tabpanel">
            <div class="row mb-3">
                @if (finishedEventsReady && avgFinishedEventsReady && chosenPrizesReady) {
                    <div class="col-12 col-lg-6 mb-3">
                        <div class="card p-4 shadow border-0 align-items-center">
                            <canvas baseChart [data]="lineChartData" [options]="lineChartOptions" type="line"></canvas>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 mt-4 mt-lg-0 mb-3">
                        <div class="card p-4 shadow border-0 align-items-center">
                            <canvas baseChart [data]="lineChartData2" [options]="lineChartOptions2" type="bar"></canvas>
                        </div>
                    </div>

                    <div id="chosenPrizes" class="col-12 col-lg-6 mt-4 mt-lg-0 mb-3">
                        <div class="card p-4 shadow border-0 align-items-center">
                            <canvas baseChart [data]="pieChartPrizesConfig.data"
                                    [options]="pieChartPrizesConfig.options"
                                    type="pie"></canvas>
                        </div>
                    </div>
                } @else {
                    <p class="mt-5 text-center">
                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true">
                    <span class="visually-hidden">Loading...</span>
                </span>
                        <ng-container i18n>Loading...</ng-container>
                    </p>
                }
            </div>
        </div>

        <div class="tab-pane fade show active" id="time_action" role="tabpanel">
            <h3 i18n>Time spent per action</h3>

            <div class="row">
                @if (finishedTimeActionsCharts?.length > 0) {
                    @for (finishedActionsChart of finishedTimeActionsCharts; track finishedActionsChart.actionId) {
                        <div class="col-12 col-lg-6 mb-3">
                            <div class="card p-4 shadow border-0 align-items-center">
                                <canvas baseChart [data]="finishedActionsChart.data"
                                        [options]="finishedActionsChart.options"
                                        type="line"></canvas>
                            </div>
                        </div>
                    }
                }
            </div>
        </div>

        <div class="tab-pane fade show active" id="amount_action" role="tabpanel">
            <h3 i18n>Amount of actions per event</h3>

            <div class="row">
                @if (finishedAmountActionsPerEventCharts?.length > 0) {
                    @for (finishedAmountActionsPerEventChart of finishedAmountActionsPerEventCharts; track finishedAmountActionsPerEventChart.eventId) {
                        <div class="col-12 col-lg-6 mb-3">
                            <div class="card p-4 shadow border-0 align-items-center">
                                <canvas baseChart [data]="finishedAmountActionsPerEventChart.data"
                                        [options]="finishedAmountActionsPerEventChart.options"
                                        type="line"></canvas>
                            </div>
                        </div>
                    }
                }
            </div>
        </div>
    </div>
</main>
