import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {PlanningConfiguration} from "@models/planning-configuration";
import {PlanningRepository} from "@repositories/planning-repository";

export declare interface PlanningConfigurationResponse {
    client_id: string;
    tokens_enabled: boolean;
    congratulations_page: boolean;
    cheat_extinction: boolean;
    vocal_assistant: boolean;
    autonomy: boolean;
    pin_code: string;
}

@Injectable({
    providedIn: 'root'
})
export class PlanningService {
    planningChange$ = new Subject<PlanningConfiguration>();

    constructor(private repository: PlanningRepository) {
    }

    private static saveConfigurationToCache(clientId: string, data: PlanningConfigurationResponse): void {
        localStorage.setItem('config-' + clientId, JSON.stringify(data));
    }

    async getConfiguration(clientId: string): Promise<PlanningConfiguration> {
        const localConfig = this.getConfigurationFromCache(clientId);
        if (localConfig) {
            return localConfig;
        }

        const response: PlanningConfigurationResponse = await this.repository.getConfiguration(clientId);
        PlanningService.saveConfigurationToCache(clientId, response);

        const config = new PlanningConfiguration(response.client_id, response.tokens_enabled, response.congratulations_page, response.cheat_extinction, response.vocal_assistant, response.autonomy, response.pin_code);
        this.planningChange$.next(config);

        return config;
    }

    async saveConfiguration(planningConfiguration: PlanningConfiguration): Promise<boolean> {
        await this.repository.saveConfiguration(planningConfiguration.ClientId, planningConfiguration.TokensEnabled, planningConfiguration.CongratulationsPage, planningConfiguration.CheatExtinction, planningConfiguration.VocalAssistant, planningConfiguration.Autonomy, planningConfiguration.PinCode);
        localStorage.removeItem('config-' + planningConfiguration.ClientId);

        return true;
    }

    private getConfigurationFromCache(clientId: string): PlanningConfiguration {
        const configData = <PlanningConfigurationResponse>JSON.parse(localStorage.getItem('config-' + clientId));
        if (!configData) {
            return null;
        }

        const config = new PlanningConfiguration(configData.client_id, configData.tokens_enabled, configData.congratulations_page, configData.cheat_extinction, configData.vocal_assistant, configData.autonomy, configData.pin_code);
        this.planningChange$.next(config);
        return config;
    }
}
