import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {VoiceRecording} from "@models/voice-recording";

@Component({
    selector: 'app-voice-recording',
    templateUrl: './voice-recording.component.html',
    standalone: true,
    imports: []
})
export class VoiceRecordingComponent {
    @ViewChild('audio', {static: true}) audio: ElementRef;

    private mediaRecorder: MediaRecorder | null = null;
    private autoTimerInterval: any;
    private exactAudioDuration: number;

    active = false;
    recording = false;
    playing = false;
    audioTimer = 0;
    audioTimerMax = 5;

    @Input() set voiceRecording(value: VoiceRecording | null) {
        this.resetAudio();

        try {
            // Some browsers don't support other types than MediaSource
            this.audio.nativeElement.srcObject = value?.soundData;
        } catch (err) {
            this.audio.nativeElement.src = URL.createObjectURL(value?.soundData);
        }

        if (!value?.soundData) {
            return;
        }

        this.active = true;

        const audioContext = new AudioContext();

        value.soundData.arrayBuffer().then((buffer) => {
            audioContext.decodeAudioData(buffer, ({duration}) => {
                this.exactAudioDuration = duration;
                this.audioTimerMax = (duration < 5) ? Math.ceil(duration) : this.audioTimerMax;

                this.changeDetector.detectChanges();
            });
        });
    }

    @Output() voiceRecordingChange = new EventEmitter<VoiceRecording>();

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    async toggleRec() {
        this.resetAudio();

        if (!this.mediaRecorder && this.active) {
            await this.initMediaRecorder();
        }

        this.recording = !this.recording;

        // Stopping recording
        if (!this.recording) {
            this.mediaRecorder.stop();
            clearInterval(this.autoTimerInterval);
            return;
        }

        this.mediaRecorder.start();

        this.autoTimerInterval = setInterval(() => {
            this.audioTimer++;
            this.changeDetector.detectChanges();

            // Stop recording at 5 seconds
            if (this.audioTimer === 5) {
                this.toggleRec();
            }
        }, 1000);
    }

    async togglePlay() {
        this.playing = !this.playing;

        // If pausing
        if (!this.playing) {
            this.audio.nativeElement.pause();
            return;
        }

        // Workaround for some browsers that need to warm up the audio
        await this.audio.nativeElement.play();
        this.audio.nativeElement.pause();
        this.audio.nativeElement.currentTime = 0;

        await this.audio.nativeElement.play();
    }

    removeVoiceRecording() {
        this.voiceRecordingChange.emit(null);
    }

    canToggleRecord() {
        return this.active && !this.playing;
    }

    canTogglePlay() {
        return this.active && !this.recording && this.exactAudioDuration > 0;
    }

    canDeleteRecording() {
        return this.active && !this.recording && this.exactAudioDuration > 0;
    }

    updateCurrentTime() {
        const ceil = Math.ceil(this.audio.nativeElement.currentTime);
        this.audioTimer = ceil < 5 ? ceil : 5;

        if (this.audio.nativeElement.currentTime >= this.exactAudioDuration) {
            this.playing = false;
        }
    }

    private async initMediaRecorder() {
        let audioChunks = [];

        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        const mimeType = MediaRecorder.isTypeSupported('audio/webm') ? 'audio/webm' : 'audio/mp4';

        this.mediaRecorder = new MediaRecorder(stream, {mimeType});
        this.mediaRecorder.onstart = () => audioChunks = [];
        this.mediaRecorder.ondataavailable = (event) => audioChunks.push(event.data);
        this.mediaRecorder.onstop = () => {
            const audioBlob = new Blob(audioChunks, {type: 'audio/mp3'});

            const voiceRecording = new VoiceRecording(null, audioBlob);
            this.voiceRecording = voiceRecording;
            this.voiceRecordingChange.emit(voiceRecording);
        };
    }

    private resetAudio() {
        this.audio.nativeElement = new Audio();
        this.audio.nativeElement.ontimeupdate = this.updateCurrentTime.bind(this);
        this.audioTimer = 0;
        this.audioTimerMax = 5;
        this.exactAudioDuration = 0;
    }

    toggleSelector() {
        this.active = !this.active;

        if (!this.active) {
            this.voiceRecordingChange.emit(null);
        }
    }
}
