import {Component, inject} from '@angular/core';
import {Client} from "@models/client";
import {UserService} from "@services/user.service";
import {NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    standalone: true,
    imports: [RouterLink, RouterLinkActive, RouterOutlet]
})
export class ClientComponent {
    client: Client;

    constructor() {
        const userService = inject(UserService);

        // In case the user changes client, subscribe to the "reload event" because angular doesn't reload the component
        inject(Router).events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                userService.currentClient.subscribe((client) => this.client = client);
            }
        });

        userService.currentClient.subscribe((client) => this.client = client);
    }
}
