import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-page',
  standalone: true,
  templateUrl: './loading-page.component.html',
})
export class LoadingPageComponent {

}
