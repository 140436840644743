import {Injectable} from '@angular/core';
import { Adapter } from '@http/adapter.service';

@Injectable({
    providedIn: 'root'
})
export class AbstractRepository {

    constructor(protected adapter: Adapter) {
    }
}
