import {inject, Injectable} from '@angular/core';
import {StatisticsRepository} from "../repositories/statistics-repository";
import {map, Observable} from "rxjs";
import {FinishedEvent} from "../models/finished-event";
import {UserService} from "@services/user.service";
import {AverageFinishedEvent} from "../models/average-finished-event";
import {ChosenPrize} from "../models/chosen-prize";
import {FinishedAction} from "../models/finished-action";
import {FinishedDay} from "../models/finished-day";
import {AmountActionsPerEvent} from "../models/amount-actions-per-event";
import {AmountActions} from "../models/amount-actions";

@Injectable({
    providedIn: 'root'
})
export class StatisticsService {
    getEventsFinished(clientId = null) {
        if (!clientId) {
            clientId = inject(UserService).currentClient.value.id;
        }

        return inject(StatisticsRepository)
            .getCountFinishedEvents(clientId)
            .pipe(
                map((response) => (
                    response.data.map((event) => (
                        new FinishedEvent(event.total, new Date(event.date))
                    )).reverse()
                ))
            );
    }

    getEventsFinishedPerWeek() {
        const currentUserId = inject(UserService).currentClient.value.id;

        return inject(StatisticsRepository)
            .getAverageFinishedEvents(currentUserId)
            .pipe(
                map((response) => ([
                    new AverageFinishedEvent($localize`Monday`, response.monday),
                    new AverageFinishedEvent($localize`Tuesday`, response.tuesday),
                    new AverageFinishedEvent($localize`Wednesday`, response.wednesday),
                    new AverageFinishedEvent($localize`Thursday`, response.thursday),
                    new AverageFinishedEvent($localize`Friday`, response.friday),
                    new AverageFinishedEvent($localize`Saturday`, response.saturday),
                    new AverageFinishedEvent($localize`Sunday`, response.sunday),
                ]))
            );
    }

    getAmountOfPrizesChosen(): Observable<ChosenPrize[]> {
        const currentUserId = inject(UserService).currentClient.value.id;

        return inject(StatisticsRepository)
            .getAmountOfPrizesChosen(currentUserId)
            .pipe(
                map(({data}) => (
                    data.map((chosenPrize) => new ChosenPrize(chosenPrize['id'], chosenPrize['title'], chosenPrize['total']))
                ))
            );
    }

    getActionsFinished() {
        const currentUserId = inject(UserService).currentClient.value.id;

        return inject(StatisticsRepository)
            .getFinishedActions(currentUserId)
            .pipe(
                map(({data}) => (
                    data.map((finishedAction) => new FinishedAction(
                        finishedAction['id'],
                        finishedAction['title'],
                        finishedAction['finished_days'].map((finishedDay) => new FinishedDay(new Date(finishedDay['date']), finishedDay['duration'], finishedDay['time_spent'])).reverse(),
                    ))
                ))
            );
    }

    getAmountActionsFinishedPerEvent() {
        const currentUserId = inject(UserService).currentClient.value.id;

        return inject(StatisticsRepository)
            .getAmountActionsPerEvent(currentUserId)
            .pipe(
                map(({data}) => (
                    data.map((finishedAction) => new AmountActionsPerEvent(
                        finishedAction['event_id'],
                        finishedAction['title'],
                        finishedAction['amount_actions'].map((finishedDay) => new AmountActions(finishedDay['total'], new Date(finishedDay['date']))).reverse(),
                    ))
                ))
            );
    }
}
