<div class="form-check form-switch d-flex">
    <div class="ms-2">
        <input type="checkbox" class="form-check-input" id="reminderSwitch" [checked]="active" (change)="toggleSelector()">
        <label class="form-check-label" for="reminderSwitch">
            <span class="d-block h6 mb-2" i18n>Reminder</span>
            <span class="d-block mb-2" i18n>Reminder that sends a notification. Format HH:MM</span>
        </label>

        <ngb-timepicker (ngModelChange)="changeTime($event)" [ngModel]="timeStruct" [disabled]="!active"></ngb-timepicker>
    </div>
</div>
