import {HttpClient} from "@angular/common/http";
import {inject, Injectable} from "@angular/core";

export type FinishedEventsResponse = {
    data: {
        total: number;
        date: string;
    }[];
}


export type AverageFinishedEventsResponse = {
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
    sunday: number;
}

@Injectable({
    providedIn: 'root'
})
export class StatisticsRepository {
    getCountFinishedEvents(clientId: string) {
        return inject(HttpClient).get<FinishedEventsResponse>(`/clients/${clientId}/finished-events`);
    }

    getAverageFinishedEvents(clientId: string) {
        return inject(HttpClient).get<AverageFinishedEventsResponse>(`/clients/${clientId}/finished-events?format=per_week`);
    }

    getAmountOfPrizesChosen(clientId: string) {
        return inject(HttpClient).get<{ data: number[] }>(`/clients/${clientId}/chosen-prizes`);
    }

    getFinishedActions(currentUserId: string) {
        return inject(HttpClient).get<{ data: [ { id: number, title: string, finished_days: { duration: number, time_spent: number }[] }[] ] }>(`/clients/${currentUserId}/finished-actions`);
    }

    getAmountActionsPerEvent(currentUserId: string) {
        return inject(HttpClient).get<{ data: { eventId: number, title: string, amount_actions: { total: number, date: string }[] }[] }>(`/clients/${currentUserId}/actions-per-event`);
    }
}
