import {ChangeDetectorRef, Component, inject} from '@angular/core';
import {Plan} from "@models/plan";
import {UserService} from "@services/user.service";
import {ToastService} from "@services/toast.service";
import {FormsModule} from "@angular/forms";
import {NgClass} from "@angular/common";
import {CreateSupervisorService} from "@services/create-supervisor.service";
import {Router} from "@angular/router";
import {PaymentService} from "@services/payment.service";

@Component({
    selector: 'app-plan',
    standalone: true,
    imports: [
        FormsModule,
        NgClass
    ],
    templateUrl: './plan.component.html',
})
export class PlanComponent {
    planJustChosen = false;
    chosenPlan: Plan | null = null;
    plans = [
        new Plan('family'),
        new Plan('pro'),
    ];
    isPaying = false;
    mustPay = false;
    loadingPaymentButton = false;
    hasAlreadyPaid = false;

    private userService = inject(UserService);
    private toast = inject(ToastService);
    private changeDetectorRef = inject(ChangeDetectorRef);
    private createSupervisorService = inject(CreateSupervisorService);
    private router = inject(Router);
    private paymentService = inject(PaymentService);
    private supervisorService = inject(CreateSupervisorService);

    constructor() {
        this.createSupervisorService.hasPlan.subscribe((plan) => {
            if (!plan) {
                return;
            }

            this.chosenPlan = plan;
            this.supervisorService.hasTrial.subscribe((hasTrial) => {
                if (hasTrial) {
                    this.mustPay = !hasTrial;
                    this.hasAlreadyPaid = hasTrial;
                    return;
                }

                this.supervisorService.hasPaid.subscribe((hasPaid) => {
                    this.mustPay = !hasPaid;
                    this.hasAlreadyPaid = hasPaid;
                });
            });
        });

        this.paymentService.isPaying().subscribe((isPaying) => {
            this.isPaying = isPaying;
            if (isPaying) {
                this.createSupervisorService.waitForSubscription().then();
            }
        });

        // Initial display
        this.userService.getChosenPlanId().subscribe((planId) => {
            if (this.planJustChosen) {
                return;
            }

            this.chosenPlan = planId ? this.plans.find((plan) => plan.id === planId) : null;
        });
    }

    choosePlan(plan: any): void {
        this.planJustChosen = true;

        const previousPlan = this.chosenPlan;
        this.chosenPlan = plan;

        this.changeDetectorRef.detectChanges();

        this.userService.choosePlan(plan).subscribe(async () => {
            const newPlan = await this.createSupervisorService.waitForNewPlan(previousPlan, plan);
            this.planJustChosen = false;

            if (newPlan?.id === plan.id) {
                this.toast.success($localize`The plan has been changed`);

                if (this.supervisorService.hasTrial.value || this.supervisorService.hasPaid.value) {
                    this.userService.getClients().subscribe((clients) => {
                        if (!clients?.length) {
                            this.router.navigate(['/', 'account', 'client', 'new']);
                        }
                    });
                }
                return;
            }

            this.toast.error($localize`The plan could not be changed`);
        });
    }

    paySubscription(): void {
        this.loadingPaymentButton = true;
        this.paymentService.startPayment(this.chosenPlan).subscribe((paymentSession) => {
            window.location.href = paymentSession.url;
            this.loadingPaymentButton = false;
        });
    }
}
