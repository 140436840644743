<div class="vh-100">
    @if (loading) {
        <div class="w-100 h-100 d-flex align-items-center justify-content-center flex-column">
            <p i18n>Hello !</p>
            <img alt="Plan Timer Logo" src="assets/PlanTimer-logo.png" width="400">
            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                <span class="visually-hidden">Saving...</span>
            </span>
        </div>
    }

    <router-outlet></router-outlet>
</div>
