import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {EventService} from "@services/event.service";
import {ActionService} from "@services/action.service";
import {Action} from "@models/action";
import {Event} from "@models/event";
import {UserService} from "@services/user.service";
import {ToastService} from "@services/toast.service";
import {combineLatest, defaultIfEmpty} from "rxjs";
import {CardsLoadingComponent} from "@components/front/cards-loading/cards-loading.component";


@Component({
    selector: 'app-action-library',
    templateUrl: './library.component.html',
    styleUrls: ['./library.component.css'],
    standalone: true,
    imports: [
        CardsLoadingComponent
    ]
})
export class LibraryComponent {
    @ViewChild('cancelBtn') cancelBtn: ElementRef;

    saving = false;
    event: Event;
    actions?: Action[];

    private selectedActions: Action[] = [];

    constructor(
        private route: ActivatedRoute,
        private eventService: EventService,
        private actionService: ActionService,
        private userService: UserService,
        private toast: ToastService,
        private router: Router,
    ) {
        this.route.queryParamMap.subscribe((params: ParamMap) => {
            const eventId = Number(params.get('eventId'));
            this.eventService.get(eventId).subscribe((event) => this.event = event);

            const actionList$ = this.actionService.getList(eventId);
            const actions$ = this.actionService.getAllFromClientId(this.userService.currentClient.value.id).pipe(
                defaultIfEmpty([])
            );

            combineLatest([actions$, actionList$]).subscribe(([actions, actionList]) => {
                this.actions = actions;

                const selectedActionsIds = actionList.map((a) => a.id);
                this.selectedActions = actions.filter((a) => selectedActionsIds.includes(a.id));
            });
        });
    }

    toggleSelect(action: Action): void {
        if (this.isSelected(action)) {
            this.selectedActions = this.selectedActions.filter((a) => a.id !== action.id);
            return;
        }

        this.selectedActions.push(action);
    }

    edit(action: Action): void {
        this.cancelBtn.nativeElement.click();
        this.router.navigate(['/', 'events', this.event.id, 'actions', action.id, 'edit']).then();
    }

    isSelected(action: Action): boolean {
        return this.selectedActions.includes(action);
    }

    saveToEvent(): void {
        this.saving = true;
        this.actionService.saveList(this.event.id, this.selectedActions).subscribe(() => {
            this.toast.success($localize`Action list saved`);
            this.cancelBtn.nativeElement.click();
        });
    }
}
