import {Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild} from '@angular/core';
import {Resource} from "@models/resource";
import {ResourceService} from "@services/resource.service";
import {ToastService} from "@services/toast.service";
import { ResourceFormComponent } from './resource-form/resource-form.component';
import {CommonModule} from "@angular/common";

@Component({
    selector: 'app-resource-picker',
    templateUrl: './resource-picker.component.html',
    styleUrls: ['./resource-picker.component.css'],
    standalone: true,
    imports: [ResourceFormComponent, CommonModule]
})
export class ResourcePickerComponent {
    @ViewChild('cancelBtn') cancelBtn: ElementRef;

    @Input() invalidResource = false;

    @Input() resource?: Resource;
    @Output() resourceChange = new EventEmitter<Resource>();

    publicResources: Resource[] = [];
    privateResources: Resource[] = [];
    saving = false;

    constructor(private resourceService: ResourceService, private toast: ToastService, private ngZone: NgZone) {
        this.resourceService.publicImages().subscribe((resources) => this.publicResources = resources);
        this.resourceService.privateImages().subscribe((resources) => this.privateResources = resources);

        this.resourceService.resourceChange$.subscribe(() => {
            this.privateResources = this.resourceService.getCachedResources();
        });
    }

    selectResource(resource: Resource): void {
        this.resourceChange.emit(resource);
        this.cancelBtn.nativeElement.click()
    }

    saveResource(resource: Resource): void {
        this.saving = true;

        // Create an image element, load the image in a 400x400 canvas and export it as a blob
        // This is done to resize small images
        const canvasImg = document.createElement('img');
        canvasImg.addEventListener('load', () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            canvas.width = 400;
            canvas.height = 400;

            ctx.drawImage(canvasImg, 0, 0, canvas.width, canvas.height);

            canvas.toBlob((blob) => {
                resource.Blob = blob;
                this.resourceService.uploadImage(resource).subscribe(() => {
                    this.saving = false;
                    URL.revokeObjectURL(canvasImg.src);
                    this.ngZone.run(() => this.toast.success($localize`Resource saved`));
                });
            })
        });

        canvasImg.src = URL.createObjectURL(resource.Blob);
    }
}
