import {Component} from '@angular/core';
import { ActivatedRoute, ParamMap, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {EventService} from "@services/event.service";
import {Event} from "@models/event";
import {ResourceService} from "@services/resource.service";
import { NgClass } from '@angular/common';
import {LoadingPageComponent} from "@components/front/loading-page/loading-page.component";

@Component({
    selector: 'app-event-edit',
    templateUrl: './event-edit.component.html',
    standalone: true,
    imports: [RouterLink, RouterLinkActive, NgClass, RouterOutlet, LoadingPageComponent]
})
export class EventEditComponent {
    eventId: number;
    event?: Event = null;
    amountOfActions = 0;
    amountOfPrizes = 0;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private eventService: EventService,
                private resourceService: ResourceService,
    ) {
        this.eventService.editEvent$.subscribe((event) => this.event = event);

        this.eventService.eventUpdated$.subscribe(() => {
            this.eventService.getStats(this.event.id).subscribe((stats) => {
                this.amountOfActions = stats.actions;
                this.amountOfPrizes = stats.prizes;
            });
        });

        this.route.paramMap.subscribe((params: ParamMap) => {
            if (params.has('eventId')) {
                this.eventId = Number(params.get('eventId'));
                this.eventService.get(this.eventId).subscribe((event) => {
                    this.eventService.editEvent$.next(event);
                });

                this.eventService.getStats(this.eventId).subscribe((stats) => {
                    this.amountOfActions = stats.actions;
                    this.amountOfPrizes = stats.prizes;
                });

                return;
            }

            this.resourceService.getResourceFromResourceId(null).subscribe((resource) => {
                this.event = new Event(null, '', resource);
            });
        });
    }

    navigate(route: (number | string)[]): void {
        this.router.navigate(route, {skipLocationChange: true});
    }
}
