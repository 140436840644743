import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

export interface PaymentSessionResponse {
    session_id: string;
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class PaymentRepository {
    constructor(private http: HttpClient) {
    }

    startFamilyPayment(): Observable<PaymentSessionResponse> {
        return this.http.post<PaymentSessionResponse>('/family-session', {});
    }

    startProPayment(): Observable<PaymentSessionResponse> {
        return this.http.post<PaymentSessionResponse>('/pro-session', {});
    }
}
