@if (event && prize) {
    <form [formGroup]="form" class="needs-validation" [ngClass]="{'was-validated': formValidated}"
          (ngSubmit)="savePrize()">
        @if (prize.id) {
            <h4 i18n>Edit {{ prize.title }}</h4>
        } @else {
            <h4 i18n>Create a new prize</h4>
        }

        <div class="row">
            <div class="col-2">
                <div class="d-table position-relative mx-auto">
                    <app-resource-picker [(resource)]="prize.resource"
                                         [invalidResource]="invalidResource()"></app-resource-picker>
                </div>
            </div>
            <div class="col-10">
                <div class="form-floating">
                    <input #prizeTitle id="action-title" class="form-control" formControlName="title"
                           placeholder="Title" i18n-placeholder
                           [ngClass]="validateClassList('title')" minlength="1" maxlength="15" required>
                    <label for="action-title" i18n>Title</label>
                    <div class="form-text ms-3" i18n>{{ prizeTitle.value.length }} / 15 allowed characters</div>
                </div>

                <div class="form-floating mt-4">
                    <input class="form-control" formControlName="tokens" type="number" id="number-input" min="0"
                           required
                           [ngClass]="validateClassList('title')">
                    <label for="number-input" i18n>Number of tokens required</label>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-10 offset-2 px-1">
                <app-timer-selector formControlName="timer" [validatedForm]="formValidated"
                                    [availableTimerTypes]="availableTimerTypes"></app-timer-selector>
            </div>

            <div class="col-10 offset-2 mt-4 px-1">
                <app-voice-recording [(voiceRecording)]="prize.voiceRecording"></app-voice-recording>
            </div>
        </div>

        <div class="mt-5 text-end">
            <button type="button" class="btn btn-secondary mx-1" (click)="cancel()" [disabled]="saving" i18n>Cancel
            </button>

            @if (prize.id) {
                <button type="button" class="btn btn-outline-danger mx-1" [disabled]="saving" i18n
                        data-bs-toggle="modal" data-bs-target="#deleteModal">
                    Delete
                </button>
            }

            @if (!prize.id) {
                <button type="button" class="btn btn-outline-warning mx-1" (click)="savePrizeAndAdd()"
                        [disabled]="saving"
                        i18n>
                    Add to {{ event?.title }}
                </button>
            }

            @if (saving) {
                <button type="button" class="btn btn-warning pe-none mx-1">
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                  <span class="visually-hidden">Saving...</span>
                </span>
                    <ng-container i18n>Saving...</ng-container>
                </button>
            } @else {
                <button type="submit" class="btn btn-warning mx-1" i18n data-cy="save-action">Save</button>
            }
        </div>
    </form>

    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="deleteModalLabel" i18n>Delete a prize</h1>
                    <button #close type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p i18n>Are you sure you want to delete this prize ?</p>
                    <p><strong i18n>This will affect any event using this prize !</strong></p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" i18n>Cancel</button>

                    @if (deleting) {
                        <button type="button" class="btn btn-danger">
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                            <span class="visually-hidden">Deleting...</span>
                        </span>
                            <ng-container i18n>Deleting...</ng-container>
                        </button>
                    } @else {
                        <button type="button" class="btn btn-danger" i18n (click)="delete()">
                            Delete
                        </button>
                    }
                </div>
            </div>
        </div>
    </div>
} @else {
    <app-loading-page></app-loading-page>
}
