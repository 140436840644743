<h4 i18n>Planning</h4>
<p><em i18n>You may add events by using the library or by creating a new event</em></p>
<div class="row mb-5">
    <div class="col-6">
        <div class="card text-white bg-primary h-100" style="cursor: pointer" (click)="openLibrary()"
             data-bs-toggle="modal"
             data-bs-target="#exampleModal">
            <div class="card-body">
                <h6 class="card-title text-white" i18n>Library</h6>
                <p class="card-text fs-sm" i18n>Add from your library</p>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card text-white bg-info" style="cursor: pointer" (click)="openEventForm()">
            <div class="card-body">
                <h6 class="card-title text-white" i18n>New event</h6>
                <p class="card-text fs-sm" i18n>Create an event and add it to {{ client.firstName }}'s planning</p>
            </div>
        </div>
    </div>
</div>

<div class="row mb-3">
    <div class="col-9 d-flex align-items-center">
        <p class="m-0"><em i18n>You may order the events by dragging the cards below to new positions</em></p>
    </div>
    <div class="col-3 d-flex">
        <input #d="ngbDatepicker" class="form-control me-2" readonly name="dp"
               [ngModel]="date" ngbDatepicker
               (ngModelChange)="onDateChange($event)">
        <button class="btn btn-primary btn-icon" type="button" (click)="d.toggle()">
            <i class="bx bx-calendar"></i>
        </button>
    </div>
</div>

<div (cdkDropListDropped)="drop($event)"
     [cdkDropListData]="events"
     cdkDropList
     cdkDropListLockAxis="x"
     cdkDropListOrientation="horizontal"
     class="d-flex border rounded-2 h-100 overflow-scroll"
     [ngClass]="{'justify-content-center': events?.length === 0}"
     style="min-height: 150px">
    @for (event of events; track event.id; let i = $index) {
        <app-card
            color="primary" header="Event" i18n-header
            [Title]="event.title" [resource]="event.resource"
            (deleteCard)="removeEventFromList(i)"></app-card>
    } @empty {
        @if (!events) {
            <app-cards-loading></app-cards-loading>
        } @else {
            <div class="d-flex flex-column p-3 align-items-center">
                <p class="text-center" i18n>No event planned on this day.</p>
                <img src="assets/add-element.svg" alt="Add events to the planning" i18n-alt width="200" height="200">
                <div class="text-center">
                    <button class="btn btn-info mt-2" (click)="openEventForm()" i18n>Create an event</button>
                </div>
            </div>
        }
    }
</div>

<div id="exampleModal" class="modal" tabindex="-1">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
