import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";

@Component({
    selector: 'save-button',
    templateUrl: './save-button.component.html',
    imports: [CommonModule],
    standalone: true
})
export class SaveButtonComponent {
    @Input() saving = false;
    @Input() class = 'btn-primary';

    @Output() save = new EventEmitter<void>();
}
