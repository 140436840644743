import {TimerType} from "@enums/timer-type.enum";

export class Timer {
    constructor(private _timerType: TimerType, private _time: number | null) {
        if (this._time === null) {
            throw new Error('A timer must have a time');
        }
    }

    get timerType(): TimerType {
        return this._timerType;
    }

    get time(): number {
        return this._time;
    }
}
