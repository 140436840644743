import {AfterViewInit, Component, forwardRef, inject, Input} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    ValidationErrors,
    Validator,
    Validators
} from '@angular/forms';
import {TimerType} from '@enums/timer-type.enum';
import {Timer} from "@models/timer";
import {NgbTimepickerConfig, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {NgClass} from '@angular/common';

@Component({
    selector: 'app-timer-selector',
    templateUrl: './timer-selector.component.html',
    styleUrls: ['./timer-selector.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimerSelectorComponent),
            multi: true
        },
        {provide: NG_VALIDATORS, useExisting: TimerSelectorComponent, multi: true},
        NgbTimepickerConfig,
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgbTimepickerModule, NgClass]
})
export class TimerSelectorComponent implements ControlValueAccessor, Validator, AfterViewInit {
    private onChange: any;
    onTouched: any;

    active = false;
    timerTypeFormControl = new FormControl({value: 0, disabled: true}, Validators.min(1));
    timerFormControl = new FormControl({
        value: {hour: null, minute: null, second: null},
        disabled: true
    }, [Validators.required, this.timeValidator]);

    @Input() validatedForm = false;
    @Input() availableTimerTypes = [
        {value: TimerType.TIMETIMER, label: 'Time timer', hint: 'Minuteur: doit effectuer l\'action avant la fin.'},
        {
            value: TimerType.RTIMETIMER,
            label: 'Reverse time timer',
            hint: 'Minuteur inversé: doit effectuer l\'action au moins pendant un certain temps.'
        }
    ];

    constructor() {
        this.timerFormControl.valueChanges.subscribe(value => {
            this.changeTimer();
        });
        this.timerTypeFormControl.valueChanges.subscribe(value => {
            this.changeTimer();
        });

        inject(NgbTimepickerConfig).spinners = false;
    }

    ngAfterViewInit(): void {
        // Hacky way to force the form to select the correct values when the form is loaded
        this.timerTypeFormControl.setValue(this.timerTypeFormControl.value);
        this.timerFormControl.setValue(this.timerFormControl.value);
    }

    timeValidator(control: AbstractControl): ValidationErrors | null {
        const seconds = control.value?.hour * 60 + control.value?.minute * 60 + control.value?.second;
        return seconds <= 0 ? {invalidTime: true} : null;
    }

    writeValue(value: Timer | null): void {
        this.active = !!value;

        if (!this.active) {
            return;
        }

        if (this.active) {
            this.timerTypeFormControl.enable();
            this.timerFormControl.enable();
        }
        this.timerTypeFormControl.setValue(value?.timerType ?? 0);

        let timeStruct = {hour: 0, minute: 0, second: 0};
        if (value?.time) {
            const date = new Date(0);
            date.setUTCSeconds(value?.time);

            timeStruct = {hour: date.getUTCHours(), minute: date.getUTCMinutes(), second: date.getUTCSeconds()};
        }
        this.timerFormControl.setValue(timeStruct);
    }

    toggleTimer() {
        this.active = !this.active;

        if (!this.active) {
            this.timerTypeFormControl.disable();
            this.timerFormControl.disable();
            this.changeTimer();
            return;
        }

        this.timerTypeFormControl.enable();
        this.timerFormControl.enable();
        this.changeTimer();
    }


    validate(control: AbstractControl): ValidationErrors | null {
        this.timerTypeFormControl.markAsTouched();
        this.timerFormControl.markAsTouched();

        if (!this.active) {
            return null;
        }

        if (this.timerTypeFormControl.valid && this.timerFormControl.valid) {
            return null;
        }

        return {invalidTimer: true};
    }

    validateClassList() {
        if (!(this.validatedForm && this.timerTypeFormControl.touched && this.timerFormControl.touched)) {
            return {};
        }

        return {
            'is-valid': this.timerTypeFormControl.valid && this.timerFormControl.valid,
            'is-invalid': this.timerTypeFormControl.invalid || this.timerFormControl.invalid,
        }
    }

    changeTimer(): void {
        const seconds = this.timerFormControl.value?.hour * 3600 + this.timerFormControl.value?.minute * 60 + this.timerFormControl.value?.second;
        const timer = this.active ? new Timer(this.timerTypeFormControl.value, seconds) : null;

        if (this.onChange) {
            this.onChange(timer);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
    }

    registerOnValidatorChange(fn: () => void): void {
    }

    setDefault() {
        if (!this.active) {
            return;
        }

        const value = this.timerFormControl.value;
        const shouldReset = [value?.hour, value?.minute, value?.second].filter((v) => v === null).length === 3;
        if (shouldReset) {
            this.timerFormControl.patchValue({
                hour: 0,
                minute: 0,
                second: 0,
            });
        }
    }
}
