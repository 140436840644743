import {inject, Injectable} from '@angular/core';
import {PaymentRepository} from "@repositories/payment-repository";
import {BehaviorSubject, map, Observable} from "rxjs";
import {PaymentSession} from "@models/payment-session";
import {Plan} from "@models/plan";

@Injectable({
    providedIn: 'root'
})
export class PaymentService {
    private paymentRepository = inject(PaymentRepository);

    private isPaying$ = new BehaviorSubject<boolean>(false);

    startPayment(plan: Plan): Observable<PaymentSession> {
        if (plan.id === 'family') {
            return this.paymentRepository.startFamilyPayment().pipe(map((paymentSession) => (
                new PaymentSession(paymentSession.url)
            )));
        } else if (plan.id === 'pro') {
            return this.paymentRepository.startProPayment().pipe(map((paymentSession) => (
                new PaymentSession(paymentSession.url)
            )));
        }
    }

    setAsPaying(): void {
        sessionStorage.setItem('isPaying', 'true');
        this.isPaying$.next(true);
    }

    isPaying(): BehaviorSubject<boolean> {
        this.isPaying$.next(Boolean(sessionStorage.getItem('isPaying')));

        return this.isPaying$;
    }

    finishedPaying(): void {
        sessionStorage.removeItem('isPaying');
        this.isPaying$.next(false);
    }
}
