@if (title && resource) {
  <div [style]="width ? 'min-width:'+width+'px;max-width:'+width+'px' : ''" cdkDrag
    class="card app-card m-2">
    <div class="card-header bg-{{color}} p-0 ps-2 m-0 w-100 d-flex justify-content-between align-items-center">
      <p class="m-0">{{title}}</p>
      @if (displayEditButton) {
        <button (click)="editCard.emit()" class=""
          style="transform: scale(.6)">
          <i class='bx bx-edit'></i>
        </button>
      }
      @if (displayDeleteButton) {
        <button (click)="deleteCard.emit()" class="btn btn-icon btn-danger rounded-circle"
          style="transform: scale(.6)">
          <i class='bx bx-trash'></i>
        </button>
      }
    </div>
    <span (click)="singleClick.emit()" class="card-image">
      <img [src]="resource.Link" alt='' class="card-img-bottom">
      @if (displayEditButton && resource.Link) {
        <div class="dark-screen">
          <span><i class='bx bxs-plus-circle'></i></span>
        </div>
      }
    </span>
  </div>
}
