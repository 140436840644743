@if (event && action) {
    <form [formGroup]="form" class="needs-validation" [ngClass]="{'was-validated': formValidated}"
          (ngSubmit)="save()">
        @if (action.id) {
            <h4 i18n>Edit {{ action.title }}</h4>
        } @else {
            <h4 i18n>Create an action</h4>
        }
        <div class="row">
            <div class="col-2">
                <div class="d-table position-relative mx-auto">
                    <app-resource-picker [(resource)]="resource"
                                         [invalidResource]="invalidResource()"></app-resource-picker>
                </div>
            </div>
            <div class="col-10">
                <div class="form-floating mb-4">
                    <input #actionTitle id="action-title" formControlName="title" class="form-control"
                           placeholder="Title" i18n-placeholder
                           [ngClass]="validateClassList('title')"
                           minlength="1" maxlength="15" required data-cy="action-title-form">
                    <label for="action-title" i18n>Title</label>
                    <div class="form-text ms-3" i18n>{{ actionTitle.value.length }} / 15 allowed characters</div>
                </div>

                <div class="form-floating mb-4">
                    <textarea #actionDescription id="action-description" formControlName="description" class="form-control"
                              placeholder="Description" i18n-placeholder
                              [ngClass]="validateClassList('description')"
                              maxlength="40" rows="5" data-cy="action-description-form"></textarea>
                    <label for="action-title" i18n>Description</label>
                    <div class="form-text ms-3" i18n>{{ actionDescription.value.length }} / 40 allowed characters</div>
                </div>

                <div class="form-floating mt-4">
                    <input class="form-control" formControlName="tokens" type="number" id="number-input" min="0"
                           required
                           [ngClass]="validateClassList('tokens')" data-cy="earned-tokens">
                    <label for="number-input" i18n>Earned tokens</label>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-10 offset-2 px-1">
                <app-timer-selector formControlName="timer" [validatedForm]="formValidated"></app-timer-selector>
            </div>
            <div class="col-10 offset-2 mt-4 px-1">
                <app-voice-recording [(voiceRecording)]="action.voiceRecording"></app-voice-recording>
            </div>
        </div>
        <div class="mt-5 mb-3 text-end">
            <button type="button" class="btn btn-secondary mx-1" (click)="cancel()" [disabled]="saving" i18n>
                Cancel
            </button>
            @if (action.id) {
                <button type="button" class="btn btn-outline-danger mx-1" [disabled]="saving" i18n
                        data-bs-toggle="modal" data-bs-target="#deleteModal">
                    Delete
                </button>
            }
            @if (!action.id) {
                <button type="button" class="btn btn-outline-success mx-1" (click)="saveActionAndAdd()"
                        [disabled]="saving" i18n data-cy="save-action-and-add">
                    Add to {{ event.title }}
                </button>
            }
            @if (saving) {
                <button type="button" class="btn btn-success pe-none mx-1" disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                        <span class="visually-hidden" i18n>Saving...</span>
                    </span>
                    <ng-container i18n>Saving ...</ng-container>
                </button>
            } @else {
                <button type="submit" class="btn btn-success mx-1" i18n data-cy="save-action">Save</button>
            }
        </div>
    </form>

    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="deleteModalLabel" i18n>Delete an action</h1>
                    <button #close type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p i18n>Are you sure you want to delete this prize ?</p>
                    <p><strong i18n>This will affect any event using this action !</strong></p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" i18n>Cancel</button>
                    @if (deleting) {
                        <button type="button" class="btn btn-danger">
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                                <span class="visually-hidden">Deleting...</span>
                            </span>
                            <ng-container i18n>Deleting...</ng-container>
                        </button>
                    } @else {
                        <button type="button" class="btn btn-danger" i18n (click)="delete()">
                            Delete
                        </button>
                    }
                </div>
            </div>
        </div>
    </div>
} @else {
    <app-loading-page></app-loading-page>
}
