import {Injectable} from '@angular/core';
import {Prize} from "@models/prize";
import {PrizeResponse} from "@services/prize.service";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class PrizeRepository {
    constructor(private http: HttpClient) {
    }

    get(prizeId: number): Observable<PrizeResponse> {
        return this.http.get<PrizeResponse>(`/prizes/${prizeId}`);
    }

    getAllFromClientId(clientId: string): Observable<PrizeResponse[]> {
        return this.http.get<PrizeResponse[]>(`/prizes/client/${clientId}`);
    }

    add(prize: Prize, clientId: string): Observable<PrizeResponse> {
        const body = {
            title: prize.title,
            client_id: clientId,
            timer: prize.timer ? {
                type: Number(prize.timer.timerType),
                time: prize.timer.time
            } : null,
            token_cost: prize.tokenCost,
            resource_id: (prize.resource.Id > 0) ? prize.resource.Id : null,
            voice_recording_id: (prize.voiceRecording) ? prize.voiceRecording.id : null
        };
        return this.http.post<PrizeResponse>('/prizes', body);
    }

    update(id: number, prize: Prize): Observable<PrizeResponse> {
        const body = {
            title: prize.title,
            timer: prize.timer ? {
                type: Number(prize.timer.timerType),
                time: prize.timer.time
            } : null,
            token_cost: prize.tokenCost,
            resource_id: (prize.resource.Id > 0) ? prize.resource.Id : null,
            voice_recording_id: (prize.voiceRecording) ? prize.voiceRecording.id : null
        };

        return this.http.put<PrizeResponse>(`/prizes/${id}`, body);
    }

    getList(eventId: number): Observable<PrizeResponse[]> {
        return this.http.get<PrizeResponse[]>(`/events/${eventId}/prizes/list`);
    }

    saveList(eventId: number, prizes: Prize[]): Observable<boolean> {
        const body = {
            prizes: prizes.map((prize) => prize.id)
        };

        return this.http.post<boolean>(`/events/${eventId}/prizes/list`, body);
    }

    delete(prizeId: number): Observable<void> {
        return this.http.delete<void>(`/prizes/${prizeId}`);
    }
}
