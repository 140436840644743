import {ResourceScope} from "@enums/resource-scope.enum";
import {SafeUrl} from "@angular/platform-browser";

export class Resource {
    private link: SafeUrl;

    constructor(private id: number | null,
                private description: string,
                private scope: ResourceScope,
                private file: string | Blob | null = null) {
    }

    get Id(): number | undefined {
        return this.id;
    }

    get Description(): string {
        return this.description;
    }

    get Blob(): Blob | null {
        return this.file instanceof Blob ? this.file : null;
    }

    set Blob(url: Blob | null) {
        this.file = url;
    }

    get Link(): string | SafeUrl {
        if (this.file instanceof Blob) {
            if (!this.link) {
                this.link = URL.createObjectURL(this.file);
            }

            return this.link;
        }

        return this.file;
    }
}
