import { Component } from '@angular/core';


@Component({
  selector: 'app-cards-loading',
  standalone: true,
  templateUrl: './cards-loading.component.html',
  styleUrl: './cards-loading.component.css'
})
export class CardsLoadingComponent {
    amountOfCards = Array(3).fill(0).map((_, index) => index + 1);
}
