import {Component, ElementRef, ViewChild} from '@angular/core';
import {Event} from "@models/event";
import {Action} from "@models/action";
import {Resource} from "@models/resource";
import {ResourceService} from "@services/resource.service";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {EventService} from "@services/event.service";
import {ActionService} from "@services/action.service";
import {UserService} from "@services/user.service";
import {Client} from "@models/client";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Timer} from "@models/timer";
import {combineLatest} from "rxjs";
import {ToastService} from "@services/toast.service";
import {VoiceRecordingComponent} from '@components/front/voice-recording/voice-recording.component';
import {TimerSelectorComponent} from '@components/front/timer-selector/timer-selector.component';
import {ResourcePickerComponent} from '@components/front/resource-picker/resource-picker.component';
import { NgClass } from '@angular/common';
import {LoadingPageComponent} from "@components/front/loading-page/loading-page.component";

@Component({
    selector: 'app-action-form',
    templateUrl: './action-form.component.html',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgClass, ResourcePickerComponent, TimerSelectorComponent, VoiceRecordingComponent, LoadingPageComponent]
})
export class ActionFormComponent {
    @ViewChild('close') close: ElementRef<HTMLButtonElement>;

    private readonly client: Client;

    event: Event;
    resource?: Resource;
    action?: Action;
    saving = false;
    deleting: boolean;
    formValidated = false;

    form: FormGroup<{
        title: FormControl<string>,
        description: FormControl<string>,
        tokens: FormControl<number>,
        timer: FormControl<Timer | null>
    }> = this.fb.group({
        title: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(15)]],
        description: ['', [Validators.maxLength(40)]],
        tokens: [0, [Validators.required, Validators.min(0)]],
        timer: [null],
    });

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private eventService: EventService,
        private actionService: ActionService,
        private resourceService: ResourceService,
        private userService: UserService,
        private toast: ToastService,
    ) {
        this.resourceService.getResourceFromResourceId(null).subscribe((resource: Resource) => {
            this.resource = resource;
        });

        this.route.parent.paramMap.subscribe((params: ParamMap) => {
            const eventId = Number(params.get('eventId'));
            this.eventService.get(eventId).subscribe((event) => {
                this.event = event
            });
        });

        this.route.paramMap.subscribe((params: ParamMap) => {
            const actionId = Number(params.get('actionId'));
            if (actionId) {
                this.actionService.get(actionId).subscribe((action) => {
                    this.action = action;
                    this.resource = action.resource;
                    this.form.patchValue({
                        title: action.title,
                        description: action.description,
                        tokens: action.tokens,
                        timer: action.timer,
                    });
                });
            } else {
                this.action = new Action(null, '', null, null, 0, null);
            }
        });

        this.client = this.userService.currentClient.value;
    }

    save(): void {
        this.formValidated = true;
        this.form.markAsDirty();

        if (this.form.invalid || !this.resource?.Id) {
            return;
        }

        this.saving = true;
        if (this.action.voiceRecording?.id === null) {
            this.resourceService.saveSound(this.action.voiceRecording, this.client).subscribe((voiceRecordingId: number) => {
                this.action.voiceRecording.id = voiceRecordingId;
            });
        }

        const f = this.form;
        const action = new Action(this.action?.id ?? null, f.get('title').value, f.get('description').value, f.get('timer').value, f.get('tokens').value, this.resource, this.action.voiceRecording);

        this.actionService.save(action, this.client).subscribe(() => {
            this.saving = false;
            this.toast.success($localize`Action saved`);
            this.router.navigate(['events', this.event.id, 'actions']).then();
        });
    }

    async saveActionAndAdd(): Promise<void> {
        this.formValidated = true;
        this.form.markAsDirty();

        if (this.form.invalid || !this.resource?.Id) {
            return;
        }

        this.saving = true;

        const action = new Action(
            this.action?.id ?? null,
            this.form.get('title').value,
            this.form.get('description').value,
            this.form.get('timer').value,
            this.form.get('tokens').value,
            this.resource
        );

        if (this.action.voiceRecording?.id === null) {
            this.resourceService.saveSound(this.action.voiceRecording, this.client).subscribe((voiceRecordingId: number) => {
                action.voiceRecording.id = voiceRecordingId;
            });
        }

        combineLatest([this.actionService.getList(this.event.id), this.actionService.save(action, this.client)]).subscribe(([actionList, action]) => {
            actionList.push(action);

            this.actionService.saveList(this.event.id, actionList).subscribe(() => {
                this.saving = false;
                this.toast.success($localize`Action saved`);
                this.router.navigate(['events', this.event.id, 'actions']).then();
            });
        });
    }

    cancel() {
        this.router.navigate(['events', this.event.id, 'actions']).then();
    }

    invalidResource(): boolean {
        return this.formValidated && !this.resource?.Id;
    }

    validateClassList(controlName: string): object {
        return {
            'is-valid': this.form.get(controlName).valid && this.formValidated,
            'is-invalid': this.form.get(controlName).invalid && this.formValidated,
        };
    }

    delete() {
        this.deleting = true;
        this.actionService.delete(this.action.id).subscribe(() => {
            this.deleting = false;
            this.close.nativeElement.click();
            this.toast.success($localize`Action deleted`);
            this.router.navigate(['events', this.event.id, 'actions']).then();
        });
    }
}
