import {Component} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../shared/components/header/header.component';
import {LoadingService} from "@services/loading.service";

@Component({
    selector: 'app-front',
    templateUrl: './front.component.html',
    standalone: true,
    imports: [HeaderComponent, RouterOutlet]
})
export class FrontComponent {
    constructor() {
        LoadingService.loading$.next(false);
    }
}
