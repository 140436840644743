import {Component, inject} from '@angular/core';
import {StatisticsService} from "../../core/statistics/services/statistics.service";
import {BaseChartDirective} from "ng2-charts";
import {ChartConfiguration, ChartData} from "chart.js";
import {HeaderComponent} from "../shared/components/header/header.component";
import {LoadingService} from "@services/loading.service";
import {UserService} from "@services/user.service";
import {FinishedAction} from "../../core/statistics/models/finished-action";
import {AmountActionsPerEvent} from "../../core/statistics/models/amount-actions-per-event";

@Component({
    selector: 'app-statistics',
    standalone: true,
    imports: [
        BaseChartDirective,
        HeaderComponent,
    ],
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent {
    clientName = '';

    lineChartOptions: ChartConfiguration['options'] = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                }
            },
        },
        plugins: {
            title: {
                display: true,
                text: $localize`Amount of finished events per day`,
            },
        }
    };

    lineChartOptions2: ChartConfiguration['options'] = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 1,
                }
            },
        },
        plugins: {
            title: {
                display: true,
                text: $localize`Average amount of finished events per day this year`,
            },
        }
    };

    pieChartPrizesConfig: ChartConfiguration = {
        type: 'pie',
        data: {
            datasets: [
                {
                    data: [],
                    backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)',
                    ],
                    label: $localize`Amount`,
                },
            ],
            labels: ['Red', 'Blue', 'Yellow'],
        },
        options: {
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: $localize`Chosen reinforcements`,
                }
            }
        },
    };

    lineChartData: ChartData<'line'> =  {
        datasets: [
            {
                data: [],
                label: $localize`Finished events`,
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.1)',
                pointBackgroundColor: 'rgb(255,17,66)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
                fill: 'origin',
            },

        ],
        labels: [],
    };

    lineChartData2: ChartData<'bar'> =  {
        datasets: [
            {
                data: [],
                label: $localize`Average finished events`,
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 2,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },

        ],
        labels: [],
    };

    finishedTimeActionsCharts: { actionId: number, data: ChartData<'line'>, options: ChartConfiguration['options'] }[] = [];

    finishedAmountActionsPerEventCharts: { eventId: number, data: ChartData<'line'>, options: ChartConfiguration['options'] }[] = [];

    finishedEventsReady = false;
    avgFinishedEventsReady = false;
    chosenPrizesReady = false;

    constructor() {
        LoadingService.loading$.next(false);

        this.clientName = inject(UserService).currentClient.value.name;

        inject(StatisticsService).getEventsFinished().subscribe((events) => {
            const values = events.map((event) => (event.total));
            const labels = events.map((event) => (event.date.toLocaleDateString()));

            this.lineChartData.datasets[0].data = values;
            this.lineChartData.labels = labels;

            this.finishedEventsReady = true;
        });

        inject(StatisticsService).getEventsFinishedPerWeek().subscribe((events) => {
            const values = events.map((event) => (event.average));
            const labels = events.map((event) => (event.day));

            this.lineChartData2.datasets[0].data = values;
            this.lineChartData2.labels = labels;

            this.avgFinishedEventsReady = true;
        });

        inject(StatisticsService).getAmountOfPrizesChosen().subscribe((chosePrizes) => {
            this.pieChartPrizesConfig.data.datasets[0].data = chosePrizes.map((prize) => (prize.total));
            this.pieChartPrizesConfig.data.labels = chosePrizes.map((prize) => (prize.title));
            this.chosenPrizesReady = true;
        });

        inject(StatisticsService).getActionsFinished().subscribe((actions: FinishedAction[]) => {
            this.finishedTimeActionsCharts = actions.map((finishedAction) => {
                const dataChart: ChartData<'line'> =  {
                    datasets: [

                        {
                            data: finishedAction.finishedDays.map((action) => (action.timeSpentToMinutes())),
                            label: $localize`Time spent`,
                            borderColor: 'rgba(255, 99, 132, 1)',
                            backgroundColor: 'rgba(255, 99, 132, 0.1)',
                            pointBackgroundColor: 'rgb(255,17,66)',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
                            fill: 'origin',
                        },
                        {
                            data: finishedAction.finishedDays.map((action) => (action.durationToMinutes())),
                            label: $localize`Time set`,
                            borderColor: 'rgb(99,120,255)',
                            backgroundColor: 'rgba(99,125,255,0.5)',
                            pointBackgroundColor: 'rgb(17,73,255)',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgb(99,125,255)',

                            fill: 'origin',
                        },
                    ],
                    labels: finishedAction.finishedDays.map((action) => (action.date.toLocaleDateString())),
                };

                const lineChartOptions: ChartConfiguration['options'] = {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                stepSize: 1,
                            }
                        },
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: $localize`Action ${finishedAction.title}`,
                        },
                    }
                };

                return {
                    actionId: finishedAction.id,
                    data: dataChart,
                    options: lineChartOptions,
                };
            });
        });

        inject(StatisticsService).getAmountActionsFinishedPerEvent().subscribe((events: AmountActionsPerEvent[]) => {
            this.finishedAmountActionsPerEventCharts = events.map((event) => {
                const dataChart: ChartData<'line'> =  {
                    datasets: [
                        {
                            data: event.amountActions.map((action) => (action.total)),
                            label: $localize`Amount of actions`,
                            borderColor: 'rgba(255, 99, 132, 1)',
                            backgroundColor: 'rgba(255, 99, 132, 0.1)',
                            pointBackgroundColor: 'rgb(255,17,66)',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
                            fill: 'origin',
                        },
                    ],
                    labels: event.amountActions.map((action) => (action.date.toLocaleDateString())),
                };

                const lineChartOptions: ChartConfiguration['options'] = {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                stepSize: 1,
                            }
                        },
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: $localize`Event ${event.title}`,
                        },
                    }
                };

                return {
                    eventId: event.eventId,
                    data: dataChart,
                    options: lineChartOptions,
                };
            });
        });
    }
}
