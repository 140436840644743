import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from "@services/toast.service";
import {EventService} from "@services/event.service";
import {Event} from "@models/event";
import {ResourceService} from "@services/resource.service";
import {UserService} from "@services/user.service";
import {Client} from "@models/client";
import {VoiceRecordingComponent} from '@components/front/voice-recording/voice-recording.component';
import {HourSelectorComponent} from '@components/front/hour-selector/hour-selector.component';
import {ResourcePickerComponent} from '@components/front/resource-picker/resource-picker.component';
import {FormsModule} from '@angular/forms';
import {NgClass} from '@angular/common';
import {LoadingPageComponent} from "@components/front/loading-page/loading-page.component";

@Component({
    selector: 'app-event-form',
    templateUrl: './event-form.component.html',
    standalone: true,
    imports: [FormsModule, NgClass, ResourcePickerComponent, HourSelectorComponent, VoiceRecordingComponent, LoadingPageComponent]
})
export class EventFormComponent {
    @ViewChild('close') close: ElementRef<HTMLButtonElement>;

    private readonly client: Client;

    event?: Event;
    saving = false;
    validate = false;
    deleting: boolean;

    constructor(private eventService: EventService,
                private resourceService: ResourceService,
                private userService: UserService,
                private router: Router,
                private route: ActivatedRoute,
                private toast: ToastService) {
        this.client = this.userService.currentClient.value;
        this.eventService.editEvent$.subscribe((event) => this.event = event);

        this.route.parent.paramMap.subscribe(async res => {
            if (res.has('eventId')) {
                return;
            }

            this.resourceService.getResourceFromResourceId(null).subscribe((resource) => {
                this.event = new Event(null, '', resource);
            });
        });
    }

    save(): void {
        this.validate = true;
        if (!this.event?.title || !this.event?.resource?.Id) {
            return;
        }

        this.saving = true;
        if (this.event.voiceRecording && this.event.voiceRecording.id === null) {
            this.resourceService.saveSound(this.event.voiceRecording, this.client).subscribe((voiceRecordingId: number) => {
                this.event.voiceRecording.id = voiceRecordingId;
                this.saveEvent();
            });
            return;
        }

        this.saveEvent();
    }

    private saveEvent(): void {
        this.eventService.save(this.event, this.client.id).subscribe((event) => {
            this.event = event;
            this.toast.success($localize`Event saved`);
            this.saving = false;
            this.router.navigate(['/', 'events', this.event.id, 'edit']).then();
        });
    }

    delete(): void {
        this.deleting = true;
        this.eventService.delete(this.event.id).subscribe(() => {
            this.deleting = false;
            this.close.nativeElement.click();
            this.toast.success($localize`Event deleted`);
            this.router.navigate(['/', 'client']).then();
        });
    }

    invalidResource(): boolean {
        return this.validate && !this.event.resource?.Id;
    }

    validateTitleClassList(): object {
        if (!this.validate) {
            return {};
        }

        return {
            'is-valid': this.event.title,
            'is-invalid': !this.event.title,
        };
    }

    cancel() {
        this.router.navigate(['/', 'client']).then();
    }
}
