@if (event) {
    <form class="needs-validation" [ngClass]="{'was-validated': validate}">
        @if (event.id) {
            <h4 i18n>Edit {{ event.title }}</h4>
        } @else {
            <h4 i18n>Create an event</h4>
        }
        <div class="row">
            <div class="col-2">
                <div class="d-table position-relative mx-auto">
                    <app-resource-picker [(resource)]="event.resource" [invalidResource]="invalidResource()"></app-resource-picker>
                </div>
            </div>
            <div class="col-10">
                <div class="form-floating mb-4">
                    <input #eventTitle class="form-control" [(ngModel)]="event.title" name="title" id="title-field"
                           placeholder="Title" i18n-placeholder
                           minlength="1" maxlength="15" required
                           [ngClass]="validateTitleClassList()"
                           data-cy="event-title-form">
                    <label for="title-field" i18n>Title</label>
                    <div class="form-text ms-3" i18n>{{ eventTitle.value.length }} / 15 allowed characters</div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-10 offset-2 px-1" data-cy="time-selector-container">
                <app-hour-selector [(time)]="event.startNotificationTime"></app-hour-selector>
            </div>
            <div class="col-10 offset-2 mt-4 px-1">
                <app-voice-recording [(voiceRecording)]="event.voiceRecording"></app-voice-recording>
            </div>
        </div>
        <div class="d-flex justify-content-end mt-5">
            <button type="button" class="btn btn-secondary mx-1" (click)="cancel()" [disabled]="saving" i18n>Cancel
            </button>
            @if (event.id) {
                <button type="button" class="btn btn-outline-danger me-2" [disabled]="saving" i18n
                        data-bs-toggle="modal" data-bs-target="#deleteModal">
                    Delete
                </button>
            }

            @if (saving) {
                <button type="button" class="btn btn-primary pe-none mx-1">
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                        <span class="visually-hidden">Saving...</span>
                    </span>
                    <ng-container i18n>Saving...</ng-container>
                </button>
            } @else {
                <button type="button" class="btn btn-primary" data-cy="save-event" (click)="save()" i18n>
                    Save
                </button>
            }
        </div>
    </form>

    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="deleteModalLabel" i18n>Delete an event</h1>
                    <button #close type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p i18n>Are you sure you want to delete this event ?</p>
                    <p><em i18n>None of your actions or prizes will be deleted.</em></p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" i18n>Cancel</button>
                    @if (deleting) {
                        <button type="button" class="btn btn-danger">
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                              <span class="visually-hidden">Deleting...</span>
                            </span>
                            <ng-container i18n>Deleting...</ng-container>
                        </button>
                    } @else {
                        <button type="button" class="btn btn-danger" i18n (click)="delete()">
                            Delete
                        </button>
                    }
                </div>
            </div>
        </div>
    </div>
} @else {
    <app-loading-page></app-loading-page>
}
