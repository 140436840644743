import {Injectable} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {UserService} from './user.service';
import {VoiceRecordingRepository} from "@repositories/voice-recording-repository";
import {ResourceScope} from "@enums/resource-scope.enum";
import {Resource} from "@models/resource";
import {ResourceRepository} from "@repositories/resource-repository";
import {VoiceRecording} from "@models/voice-recording";
import {Client} from "@models/client";
import {AsyncSubject, BehaviorSubject, combineLatest, forkJoin, map, mergeMap, Observable, Subject, tap} from "rxjs";

export interface ResourceResponse {
    id: number;
    description: string;
    scope: ResourceScope;
    url: string | Blob;
    user_id: string;
}

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    private privateResources = new Map<number, Resource>();
    private downloadingResources = new Map<number, AsyncSubject<Resource>>();
    private voiceRecordings = new Map<number, VoiceRecording>();

    resourceChange$ = new Subject<void>();

    constructor(private repository: ResourceRepository, private sanitizer: DomSanitizer, private userService: UserService, private voiceRecordingRepository: VoiceRecordingRepository) {
    }

    getDefaultResource(): Resource {
        return new Resource(null, 'Default', ResourceScope.PUBLIC, 'https://storage.sbg.cloud.ovh.net/v1/AUTH_c0e0ef21a99a4b60ac222d53a2c8445d/user-public-images/default.jpg');
    }

    publicImages(): Observable<Resource[]> {
        return this.repository.publicImages().pipe(map(
            (data) => data.map((r: ResourceResponse) => new Resource(r.id, r.description, r.scope, r.url))
        ));
    }

    getCachedResources(): Resource[] {
        return Array.from(this.privateResources.values());
    }

    privateImages(): Observable<Resource[]> {
        const client = this.userService.currentClient.value;
        if (!client) {
            return new BehaviorSubject([]);
        }

        return this.repository.privateImages(client.id).pipe(
            map((responses) => {
                const resourceIds = responses.map((response) => response.id);
                const currentResourceIds = Array.from(this.privateResources.keys());

                // Diff between currently saved resources in memory and ids of the resources returned by the api
                const diff = currentResourceIds
                    .filter((resourceId) => !resourceIds.includes(resourceId))
                    .concat(resourceIds.filter((resourceId) => !currentResourceIds.includes(resourceId)));

                return responses.filter((response) => diff.includes(response.id));
            }),
            map((responses) => {
                // Resources to delete
                const resourcesToDeleteFromMap = responses.filter((resource) => this.privateResources.has(resource.id));
                for (const resource of resourcesToDeleteFromMap) {
                    this.privateResources.delete(resource.id);
                }

                return responses.filter((resourceId) => !resourcesToDeleteFromMap.includes(resourceId));
            }),
            mergeMap((resourcesToFetch) => {
                if (resourcesToFetch.length === 0) {
                    return new BehaviorSubject([]);
                }

                // Resources to fetch
                const resourcesToFetch$ = resourcesToFetch.map((resource) => {
                    return this.repository.getPrivateImage(resource.id).pipe(map((res) => {
                        const resourceToSave = new Resource(resource.id, '', ResourceScope.PRIVATE, res);
                        this.privateResources.set(resource.id, resourceToSave);

                        return resourceToSave;
                    }))
                });

                return combineLatest(resourcesToFetch$);
            }),
            map(() => {
                return Array.from(this.privateResources.values())
            })
        );
    }

    uploadImage(resource: Resource): Observable<Resource | null> {
        const client = this.userService.currentClient.value;

        return this.repository.uploadImage(client.id, resource).pipe(
            mergeMap((response) => this.getResourceFromResourceId(response.id))
        );
    }

    getResourceFromResourceId(resourceId?: number): Observable<Resource> {
        if (!resourceId) {
            // Put the default image id
            return new BehaviorSubject(this.getDefaultResource());
        }

        if (this.privateResources.has(resourceId)) {
            return new BehaviorSubject(this.privateResources.get(resourceId));
        }

        // If the resource is currently downloading, return the observable (AsyncSubject)
        if (this.downloadingResources.has(resourceId)) {
            return this.downloadingResources.get(resourceId);
        }

        // Return an AsyncSubject that will be completed when the resource is downloaded by another Observable
        const async$ = new AsyncSubject<Resource>();
        this.downloadingResources.set(resourceId, async$);

        this.getResourceInfos(resourceId).pipe(
            mergeMap((resourceInfos) => this.getResource(resourceInfos).pipe(
                tap((resource) => {
                    this.privateResources.set(resourceId, resource);
                    this.downloadingResources.delete(resourceId);
                    async$.next(resource);
                    async$.complete();
                }))
            ),
        ).subscribe();

        return async$;
    }

    delete(resourceId: number): Observable<void> {
        return this.repository.delete(resourceId).pipe(
            tap(() => this.privateResources.delete(resourceId))
        );
    }

    getVoiceRecording(voiceRecordingId: number | null): Observable<VoiceRecording | null> {
        if (!voiceRecordingId) {
            return new BehaviorSubject<null>(null);
        }

        if (this.voiceRecordings.has(voiceRecordingId)) {
            return new BehaviorSubject<VoiceRecording>(this.voiceRecordings.get(voiceRecordingId));
        }

        const getInfos$ = this.voiceRecordingRepository.getInfos(voiceRecordingId);
        const getFile$ = this.voiceRecordingRepository.getFile(voiceRecordingId);

        return forkJoin([getInfos$, getFile$]).pipe(map(([infos, file]) => {
            const voiceRecording = new VoiceRecording(infos.id, file);
            this.voiceRecordings.set(voiceRecordingId, voiceRecording);

            return voiceRecording;
        }));
    }

    saveSound(voiceRecording: VoiceRecording, client: Client): Observable<number> {
        return this.voiceRecordingRepository.save(voiceRecording, client).pipe(
            map((response) => response.id)
        );
    }

    deleteVoiceRecording(recordingId: number): Observable<void> {
        return this.voiceRecordingRepository.delete(recordingId);
    }

    private resourceToSafeUrl(res: Blob): SafeUrl {
        const unsafeImageUrl = URL.createObjectURL(res);
        return this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
    }

    private getResourceInfos(resourceId: number): Observable<ResourceResponse> {
        let resourceInfos: ResourceResponse = JSON.parse(localStorage.getItem('resourceInfos-' + resourceId) ?? '{}');

        if (resourceInfos.id) {
            return new BehaviorSubject(resourceInfos);
        }

        return this.repository.resourceInfos(resourceId).pipe(tap((resourceInfos) => {
            localStorage.setItem('resourceInfos-' + resourceId, JSON.stringify(resourceInfos));
        }));
    }

    private getResource(resourceInfos: ResourceResponse): Observable<Resource> {
        if (resourceInfos.scope === ResourceScope.PRIVATE) {
            return this.repository.getPrivateImage(resourceInfos.id).pipe(
                map((data) => {
                    const resource = new Resource(resourceInfos.id, resourceInfos.description, resourceInfos.scope, data);
                    this.privateResources.set(resourceInfos.id, resource);

                    return resource;
                }),
                tap(() => this.resourceChange$.next()),
            );
        }

        return new BehaviorSubject(new Resource(resourceInfos.id, resourceInfos.description, resourceInfos.scope, resourceInfos.url))
            .pipe(tap(() => this.resourceChange$.next()));
    }
}
