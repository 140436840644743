import {Component} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { CdkDragDrop, moveItemInArray, CdkDropList } from '@angular/cdk/drag-drop';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ToastService} from "@services/toast.service";
import {PrizeService} from "@services/prize.service";
import {EventService} from "@services/event.service";
import {Prize} from "@models/prize";
import {Event} from "@models/event";
import { CardComponent } from '@components/front/card/card.component';
import {LoadingPageComponent} from "@components/front/loading-page/loading-page.component";
import {CardsLoadingComponent} from "@components/front/cards-loading/cards-loading.component";
import {NgClass} from "@angular/common";

@Component({
    selector: 'app-prize-list',
    templateUrl: './prize-list.component.html',
    standalone: true,
    imports: [CdkDropList, CardComponent, RouterOutlet, LoadingPageComponent, CardsLoadingComponent, NgClass]
})
export class PrizeListComponent {
    event: Event = null;
    prizes: Prize[];

    prizesChanged$ = new Subject<void>();

    constructor(private route: ActivatedRoute,
                private router: Router,
                private eventService: EventService,
                private prizeService: PrizeService,
                private toast: ToastService) {
        this.route.parent.paramMap.subscribe(async (params) => {
            const eventId = Number(params.get('eventId'));
            this.eventService.get(eventId).subscribe((event) => this.event = event);
            this.prizeService.getList(eventId).subscribe((prizes) => this.prizes = prizes);
        });

        this.prizeService.prizeList$.subscribe((prizes) => this.prizes = prizes);

        this.prizesChanged$.pipe(debounceTime(1000)).subscribe(() => this.saveList());
    }

    saveList(): void {
        this.prizeService.saveList(this.event.id, this.prizes).subscribe(() => {
            this.toast.success($localize`Prize list saved`);
        });
    }

    /**
     * Drag and drop event used to add cards to a new list
     */
    drop(event: CdkDragDrop<unknown[]>): void {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.prizesChanged$.next();
    }

    removePrizeFromList(i: number): void {
        this.prizes.splice(i, 1);
        this.prizesChanged$.next();
    }

    openLibrary() {
        this.router.navigate(['library'], {
            relativeTo: this.route,
            skipLocationChange: true,
            queryParams: {eventId: this.event.id}
        });
    }

    openPrizeForm() {
        this.router.navigate(['/', 'events', this.event.id, 'prizes', 'new']);
    }
}
