import {AbstractRepository} from './abstract-repository';
import {Injectable} from '@angular/core';
import {PlanningConfigurationResponse} from "@services/planning.service";

@Injectable({
    providedIn: 'root'
})
export class PlanningRepository extends AbstractRepository {
    async getConfiguration(clientId: string): Promise<PlanningConfigurationResponse> {
        return this.adapter.get<PlanningConfigurationResponse>('/clients/' + clientId + '/settings');
    }

    saveConfiguration(clientId: string, tokensEnabled: boolean, congratulationsPage: boolean, cheatExtinction: boolean, vocalAssistant: boolean, autonomy: boolean, pinCode: string): Promise<boolean> {
        return this.adapter.put('/clients/' + clientId + '/settings', {
            tokens_enabled: tokensEnabled,
            congratulations_page: congratulationsPage,
            cheat_extinction: cheatExtinction,
            vocal_assistant: vocalAssistant,
        });
    }
}
