import {Component} from '@angular/core';
import {UserService} from "@services/user.service";
import {Client} from "@models/client";
import { FormBuilder, FormControl, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {ToastService} from "@services/toast.service";
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgClass]
})
export class ProfileComponent {
    private client: Client;

    currentYear = new Date().getFullYear();

    formProfile = this.fb.group({
        first_name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        birth_year: new FormControl('', [Validators.required, this.validateBirthYear()]),
        gender: new FormControl('', [Validators.required, this.genderValidator()]),
    });
    profileValidated = false;

    formPassword = this.fb.group({
        username: new FormControl({value: '', disabled: true}),
        password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
    passwordValidated = false;
    passwordSaving = false;
    profileSaving = false;

    constructor(private userService: UserService, private fb: FormBuilder, private toast: ToastService) {
        userService.currentClient.subscribe((client) => {
            this.client = client;

            this.formProfile.patchValue({
                first_name: this.client.firstName,
                last_name: this.client.lastName,
                birth_year: String(this.client.birth),
                gender: this.client.gender,
            });

            this.formPassword.controls.username.setValue(this.client.username);
        });
    }

    private validateBirthYear(): ValidatorFn {
        return (control: FormControl) => {
            const birthYear = control.value;
            const currentYear = new Date().getFullYear();

            return birthYear > 1900 && birthYear <= currentYear ? null : {invalidBirthYear: true};
        };
    }

    private genderValidator(): ValidatorFn {
        return (control: FormControl) => {
            const gender = control.value;

            return ['m', 'f'].includes(gender?.toLowerCase()) ? null : {invalidGender: true};
        };
    }

    saveProfile() {
        this.profileValidated = true;
        this.formProfile.markAsDirty();

        if (!this.formProfile.valid) {
            return;
        }

        this.profileSaving = true;

        const controls = this.formProfile.controls;

        const client = new Client(
            this.client.id,
            controls.first_name.value,
            controls.last_name.value,
            controls.gender.value,
            Number(controls.birth_year.value),
            this.client.username,
            this.client.name,
        );

        this.userService.updateClientProfile(client).subscribe(() => {
            this.profileSaving = false;
            this.profileValidated = false;
            this.toast.success($localize`Profile updated`);
            this.userService.reloadCachedClients();
        });

    }

    savePassword() {
        this.passwordValidated = true;
        this.formPassword.markAsDirty();

        if (!this.formPassword.valid) {
            return;
        }

        this.passwordSaving = true;

        this.userService.updatePassword(this.client, this.formPassword.controls.password.value).subscribe(() => {
            this.passwordSaving = false;
            this.passwordValidated = false;
            this.formPassword.reset();
            this.toast.success($localize`Password updated`);
        });
    }
}
