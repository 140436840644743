import {FinishedDay} from "./finished-day";

export class FinishedAction {
    constructor(
        public readonly id: number,
        public readonly title: string,
        public readonly finishedDays: FinishedDay[],
    ) {
    }
}
