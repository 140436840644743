import {Component, ElementRef, ViewChild} from '@angular/core';
import {EventService} from "@services/event.service";
import {Event} from "@models/event";
import {UserService} from "@services/user.service";
import {ToastService} from "@services/toast.service";
import {Client} from "@models/client";
import {ActivatedRoute, Router} from "@angular/router";
import {combineLatest, defaultIfEmpty} from "rxjs";
import {CardsLoadingComponent} from "@components/front/cards-loading/cards-loading.component";

@Component({
    selector: 'app-event-library',
    templateUrl: './library.component.html',
    styleUrls: ['./library.component.css'],
    standalone: true,
    imports: [
        CardsLoadingComponent
    ],
})
export class LibraryComponent {
    @ViewChild('cancelBtn') cancelBtn: ElementRef;

    private date: Date;

    client: Client;
    events: Event[];
    selectedEvents: Event[] = [];
    saving = false;

    constructor(
        private router: Router,
        private eventService: EventService,
        private userService: UserService,
        private toast: ToastService,
        private route: ActivatedRoute
    ) {
        this.route.queryParams.subscribe((params) => {
            this.client = this.userService.currentClient.value;

            this.date = new Date(params.date);
            const actionList$ = this.eventService.getClientEventList(this.client.id, this.date);
            const actions$ = this.eventService.allEventsFromClientId(this.client.id).pipe(
                defaultIfEmpty([])
            );

            combineLatest([actions$, actionList$]).subscribe(([events, eventList]) => {
                this.events = events;
                const selectedEventIds = eventList.map((a) => a.id);
                this.selectedEvents = events.filter((a) => selectedEventIds.includes(a.id));
            });
        });
    }

    edit(event: Event) {
        this.router.navigate(['/', 'events', event.id, 'edit']);
        this.cancelBtn.nativeElement.click();
    }

    toggleSelect(event: Event) {
        if (this.isSelected(event)) {
            this.selectedEvents = this.selectedEvents.filter((a) => a.id !== event.id);
            return;
        }

        this.selectedEvents.push(event);
    }

    isSelected(event: Event) {
        return this.selectedEvents.includes(event);
    }

    saveToPlanning(): void {
        this.saving = true;
        this.eventService.saveClientList(this.selectedEvents, this.client.id, this.date).subscribe(() => {
            this.saving = false;
            this.toast.success($localize`Action list saved`);
            this.cancelBtn.nativeElement.click();
            this.selectedEvents = [];
            this.router.navigate(['/', 'client', 'events']).then();
        });
    }
}
