import {Resource} from './resource';
import {User} from './user';
import {VoiceRecording} from './voice-recording';
import {Timer} from "@models/timer";

export class Prize {
    private _author?: User;

    constructor(
        private _id: number | null,
        private _title: string,
        private _timer: Timer | null,
        private _tokenCost: number = 0,
        private _resource: Resource = null,
        private _voiceRecording: VoiceRecording = null) {
    }

    get id(): number {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get timer(): Timer {
        return this._timer;
    }

    set timer(value: Timer) {
        this._timer = value;
    }

    get tokenCost(): number {
        return this._tokenCost;
    }

    set tokenCost(value: number) {
        this._tokenCost = value;
    }

    get resource(): Resource {
        return this._resource;
    }

    set resource(value: Resource) {
        this._resource = value;
    }

    get author(): User | undefined {
        return this._author;
    }

    set author(author: User | undefined) {
        this._author = author;
    }

    get voiceRecording(): VoiceRecording | null {
        return this._voiceRecording;
    }

    set voiceRecording(value: VoiceRecording | null) {
        this._voiceRecording = value;
    }
}
