export class PlanningConfiguration {
    constructor(private clientId: string, private tokensEnabled: boolean, private congratulationsPage: boolean, private cheatExtinction: boolean, private vocalAssistant: boolean, private autonomy: boolean, private pinCode: string) {
    }

    get ClientId(): string {
        return this.clientId;
    }

    get TokensEnabled(): boolean {
        return this.tokensEnabled;
    }

    get CongratulationsPage(): boolean {
        return this.congratulationsPage;
    }

    get CheatExtinction(): boolean {
        return this.cheatExtinction;
    }

    get VocalAssistant(): boolean {
        return this.vocalAssistant;
    }

    get Autonomy(): boolean {
        return this.autonomy;
    }

    get PinCode(): string {
        return this.pinCode;
    }
}
