import {inject, Injectable} from '@angular/core';
import {AsyncSubject, BehaviorSubject, map, Observable, tap} from 'rxjs';
import {UserRepository} from "@repositories/user-repository";
import {Client} from "@models/client";
import {UserType} from "@enums/user-type.enum";
import {User} from "@models/user";
import {CookieService} from "ngx-cookie-service";
import {Plan} from "@models/plan";
import {AuthService} from "@auth0/auth0-angular";
import {CreateSupervisorService} from "@services/create-supervisor.service";

export declare interface UserResponse {
    birth: string;
    id: string;
    first_name: string;
    last_name: string;
    name: string;
    username: string;
    email: string;
    gender: string;
    type: UserType;
    expires: number; // TTL
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private authService = inject(AuthService);
    private userRepository = inject(UserRepository);

    private currentClient$ = new BehaviorSubject<Client>(null);
    private clients$: BehaviorSubject<Client[] | null>;
    private downloadingUsers = new Map<string, AsyncSubject<User>>();

    private loadingClients = false;

    getClients(): Observable<Client[]> {
        if (this.loadingClients || this.clients$?.value?.length > 0) {
            return this.clients$;
        }

        this.clients$ = new BehaviorSubject<Client[] | null>(null);

        const clients = this.userRepository.getAllCachedClients();
        if (clients.length > 0) {
            this.clients$.next(clients);
            return this.clients$;
        }

        this.loadingClients = true;
        this.userRepository.getClients().subscribe({
            next: (clients) => {
                this.clients$.next(clients);
                this.loadingClients = false;
            },
            error: () => {
                this.clients$.next(null);
            }
        });

        return this.clients$;
    }

    reloadCachedClients(): void {
        this.clients$.next(this.userRepository.getAllCachedClients());
    }

    updateClientsList(): Observable<Client[]> {
        if (!this.clients$) {
            this.clients$ = new BehaviorSubject<Client[]>([]);
        }

        return this.userRepository.getClients().pipe(tap((clients) => this.clients$.next(clients)));
    }

    get currentClient(): BehaviorSubject<Client> {
        if (this.currentClient$.value) {
            return this.currentClient$;
        }

        if (this.userRepository.getCachedCurrentClient()) {
            this.getClients().subscribe((clients) => {
                if (!clients?.length) {
                    return;
                }

                const cachedClientId = this.userRepository.getCachedCurrentClient();
                const currentClient = clients.find((client) => client.id === cachedClientId);
                this.setCurrentClient(currentClient);
            });

            return this.currentClient$;
        }

        this.getClients().subscribe((clients) => {
            if (!clients?.length) {
                return;
            }

            this.setCurrentClient(clients[0])
        });

        return this.currentClient$;
    }

    setCurrentClient(client: Client): void {
        this.currentClient$.next(client);
        this.userRepository.setCachedCurrentClient(client);
    }

    /**
     * TODO: keep this
     */
    getUsersAssociatedToClient(clientId: string): Observable<User> {
        return this.userRepository.getUsersAssociatedToClient(clientId);
    }

    /**
     * TODO: keep this, check the subscriber: should it be a pipe instead of a subscriber ?
     */
    saveUser(user: User): Observable<void> {
        return this.userRepository.updateSupervisor(user);
    }

    saveClient(client: Client): Observable<Client> {
        if (client.id) {
            return this.userRepository.updateClient(client);
        }

        return this.userRepository.createClient(client);
    }

    isUsernameValid(username: string): Observable<boolean> {
        return this.userRepository.isUsernameValid(username);
    }

    updateProfile(user: User): Observable<void> {
        return this.userRepository.updateProfile(user);
    }

    updateClientProfile(client: Client): Observable<void> {
        return this.userRepository.updateClientProfile(client);
    }

    updatePassword(client: Client, password: string): Observable<boolean> {
        return this.userRepository.updatePassword(client.id, password);
    }

    /**
     * TODO: keep this
     */
    sendVerificationEmail(): Observable<void> {
        return this.userRepository.sendVerificationEmail();
    }

    getUser(id: string): Observable<User> {
        const cachedUser = this.userRepository.getCachedUser(id);
        if (cachedUser) {
            return new BehaviorSubject<User>(cachedUser);
        }

        if (this.downloadingUsers.has(id)) {
            return this.downloadingUsers.get(id);
        }

        const async$ = new AsyncSubject<User>();
        this.downloadingUsers.set(id, async$);

        this.userRepository.getUser(id).pipe(tap((user) => {
            this.userRepository.cacheUser(user);
            this.downloadingUsers.delete(id);
            async$.next(user);
            async$.complete();
        })).subscribe();

        return async$;
    }

    choosePlan(plan: Plan): Observable<void> {
        return this.userRepository.switchPlan(plan);
    }

    getChosenPlanId(): Observable<string|null> {
        return this.authService.user$.pipe(map((user) => (
            user['https://plan-timer.com/app_metadata']['plan_id'] ?? null
        )));
    }
}
