<h4>Settings</h4>

<div class="container">
    <div class="row mb-4">
        <label for="fn" class="form-label fs-base" i18n>Name</label>
        <input id="fn" [(ngModel)]="name" name="name" class="form-control form-control-lg" required>
        <div class="invalid-feedback" i18n>Please enter your name</div>
    </div>

    <div class="row my-4">
        <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input" id="tokens" [(ngModel)]="tokens">
            <label class="form-check-label" for="tokens">
                <span class="h6 d-block mb-2" i18n>Tokens</span>
                <span class="fs-sm text-muted" i18n>Turn on to offer tokens to your client linked to certain actions</span>
            </label>
        </div>
    </div>

<!--    <div class="row my-4">-->
<!--        <div class="form-check form-switch">-->
<!--            <input type="checkbox" class="form-check-input" id="congratulations" [(ngModel)]="congratulations">-->
<!--            <label class="form-check-label" for="congratulations">-->
<!--                <span class="h6 d-block mb-2" i18n>Congratulations</span>-->
<!--                <span class="fs-sm text-muted" i18n>Turn on the congratulations page at the end of each event</span>-->
<!--            </label>-->
<!--        </div>-->
<!--    </div>-->

    <div class="row my-4">
        <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input" id="cheatExtinction" [(ngModel)]="cheatExtinction">
            <label class="form-check-label" for="cheatExtinction">
                <span class="h6 d-block mb-2" i18n>Cheat extinction</span>
                <span class="fs-sm text-muted" i18n>Turn on the cheat extinction to avoid skipping actions</span>
            </label>
        </div>
    </div>

    <div class="row my-4">
        <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input" id="voiceRecording" [(ngModel)]="voiceRecording">
            <label class="form-check-label" for="voiceRecording">
                <span class="h6 d-block mb-2" i18n>Voice recordings</span>
                <span
                    class="fs-sm text-muted" i18n>Enable voice recordings that will be played for each events or actions</span>
            </label>
        </div>
    </div>

    <div class="d-flex flex-column align-items-end">
        <save-button [saving]="saving" (save)="save()"></save-button>
    </div>
</div>
