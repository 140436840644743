import {inject, Injectable} from '@angular/core';
import {ResourceService} from './resource.service';
import {map, Observable, Subject, tap} from 'rxjs';
import {UserService} from './user.service';
import {PrizeRepository} from "@repositories/prize-repository";
import {Client} from "@models/client";
import {Prize} from "@models/prize";
import {Timer} from "@models/timer";
import {TimerType} from "@enums/timer-type.enum";
import {EventService} from "@services/event.service";

export declare interface PrizeResponse {
    id: number;
    title: string;
    token_cost: number;
    time: number;
    user_id: string;
    resource_id: number;
    voice_recording_id: number;
}

@Injectable({
    providedIn: 'root'
})
export class PrizeService {
    prizeList$ = new Subject<Prize[]>();

    private eventService = inject(EventService);

    constructor(private repository: PrizeRepository, private resourceService: ResourceService, private userService: UserService) {
    }

    getPrizesFromCurrentClient(): Observable<Prize[]> {
        const currentClient: Client = this.userService.currentClient.value;
        return this.repository.getAllFromClientId(currentClient.id).pipe(map((prizeResponses: PrizeResponse[]) => {
            return prizeResponses.map((prizeResponse: PrizeResponse) => this.mapPrizeResponseToPrize(prizeResponse));
        }));
    }

    private mapPrizeResponseToPrize(response: PrizeResponse): Prize {
        const timer = response.time ? new Timer(TimerType.TIMETIMER, response.time) : null;
        const prize = new Prize(response.id, response.title, timer, response.token_cost, null, null);

        this.userService.getUser(response.user_id).subscribe((user) => {
            prize.author = user;
        });

        this.resourceService.getResourceFromResourceId(response.resource_id).subscribe((resource) => {
            prize.resource = resource;
        });

        this.resourceService.getVoiceRecording(response.voice_recording_id).subscribe((voiceRecording) => {
            prize.voiceRecording = voiceRecording;
        });

        return prize;
    }

    get(prizeId: number): Observable<Prize> {
        return this.repository.get(prizeId).pipe(map((response: PrizeResponse) => this.mapPrizeResponseToPrize(response)));
    }

    save(prize: Prize): Observable<Prize> {
        let prizeResponse: Observable<PrizeResponse>;

        if (prize.id) {
            prizeResponse = this.repository.update(prize.id, prize).pipe(
                tap(() => this.eventService.eventUpdated$.next())
            );
        } else {
            const client = this.userService.currentClient.value;
            prizeResponse = this.repository.add(prize, client.id);
        }

        return prizeResponse.pipe(map((response: PrizeResponse) => this.mapPrizeResponseToPrize(response)));
    }

    getList(eventId: number): Observable<Prize[]> {
        return this.repository.getList(eventId).pipe(map((prizeResponses: PrizeResponse[]) => {
            return prizeResponses.map((prizeResponse: PrizeResponse) => this.mapPrizeResponseToPrize(prizeResponse));
        }));
    }

    saveList(eventId: number, prizes: Prize[]): Observable<boolean> {
        return this.repository.saveList(eventId, prizes).pipe(
            tap(() => this.prizeList$.next(prizes)),
            tap(() => this.eventService.eventUpdated$.next())
        );
    }

    delete(prizeId: number): Observable<void> {
        return this.repository.delete(prizeId);
    }
}
