import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '@auth0/auth0-angular';
import { environment } from '@environments/environment';

/**
 * @deprecated
 */
@Injectable({
    providedIn: 'root'
})
export class Adapter {
    static readonly DEFAULT_PREFIX = '';

    private url: string;
    private headers: HttpHeaders;
    private prefix: string;

    constructor(protected http: HttpClient, protected auth: AuthService) {
        // Make contact, auth etc
        this.prefix = Adapter.DEFAULT_PREFIX;
        this.url = environment.api_url;
        this.headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }

    set Prefix(prefix: string) {
        this.prefix = prefix;
    }

    set ContentType(type: string) {
        this.headers = this.headers.set('Content-Type', type);
    }

    async get<T>(endpoint: string): Promise<T> {
        await this.init();

        return await this.http.get<T>(this.url + this.prefix + endpoint, {headers: this.headers}).toPromise();
    }

    async getBlob(endpoint: string): Promise<Blob> {
        await this.init();

        return await this.http.get(this.url + this.prefix + endpoint, {
            headers: this.headers,
            responseType: 'blob'
        }).toPromise();
    }

    async post<T>(endpoint: string, body: object): Promise<T> {
        await this.init();

        return await this.http.post<T>(this.url + this.prefix + endpoint, body, {headers: this.headers}).toPromise();
    }

    async put<T>(endpoint: string, body: object): Promise<T> {
        await this.init();

        return await this.http.put<T>(this.url + this.prefix + endpoint, body, {headers: this.headers}).toPromise();
    }

    async delete<T>(endpoint: string): Promise<T> {
        await this.init();

        return await this.http.delete<T>(this.url + this.prefix + endpoint, {headers: this.headers}).toPromise();
    }

    async postFormData<T>(endpoint: string, body: FormData): Promise<T> {
        this.headers = new HttpHeaders();
        await this.init();

        return await this.http.post<T>(this.url + this.prefix + endpoint, body, {headers: this.headers}).toPromise();
    }

    async patch<T>(endpoint: string, body: object) {
        await this.init();

        return await this.http.patch<T>(this.url + this.prefix + endpoint, body, {headers: this.headers}).toPromise();
    }

    private async init() {
        const token = await this.auth.getAccessTokenSilently().toPromise();

        this.headers = this.headers.set('Authorization', 'Bearer ' + token);
    }
}
