<h3 i18n>Choose a plan</h3>

@if (!chosenPlan) {
    <p><em i18n>To continue, you have to choose a plan. Don't worry, you can change it later.</em></p>
} @else if (!mustPay && !hasAlreadyPaid) {
    <div class="alert d-flex alert-success" role="alert">
        <i class="bx bxs-offer lead me-3"></i>
        <div i18n>
            You are now on a free 1 month trial. If you need anything, please contact us at <a href="mailto:contact@plan-timer.com">contact&#64;plan-timer.com</a>.
        </div>
    </div>
}

@if (mustPay && !isPaying) {
    <div class="alert alert-info mb-4" role="alert">
        <h4 class="pt-2 alert-heading" i18n>Your trial is finished !</h4>

        <hr class="opacity-25 my-0" style="color: currentColor;">
        <p class="mt-3 mb-2" i18n>
            You can still change your subscription before paying, then you will be able to freely use Plan Timer.
        </p>

        <p class="mt-4 mb-1 text-end">
            @if (!loadingPaymentButton) {
                <button class="btn btn-primary w-100" i18n (click)="paySubscription()">Pay</button>
            } @else {
                <button class="btn btn-primary w-100" disabled>
                    <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true">
                        <span class="visually-hidden">Saving...</span>
                    </span>
                    <ng-container i18n>Pay</ng-container>
                </button>
            }
        </p>
    </div>
}

<div class="row mb-4">
    <div class="col">
        <div class="card h-100">
            <div class="card-body d-flex flex-column justify-content-between">
                <h5 class="card-title" i18n>Family</h5>
                <p class="card-text fs-sm" i18n>Access all functionalities needed to create a planning.</p>
                <div>
                    @if (!planJustChosen && chosenPlan?.id === plans[0].id) {
                        <button class="btn btn-sm btn-primary" disabled i18n>
                            Sélectionné
                        </button>
                    } @else if (planJustChosen && chosenPlan?.id === plans[0].id) {
                        <button type="button" class="btn btn-sm btn-primary" disabled>
                            <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true">
                                <span class="visually-hidden">Saving...</span>
                            </span>
                            <ng-container i18n>Saving...</ng-container>
                        </button>
                    } @else {
                        <button class="btn btn-sm btn-outline-primary" data-cy="choose-plan"
                                i18n (click)="choosePlan(plans[0])" [disabled]="hasAlreadyPaid">
                            Choose
                        </button>
                    }
                </div>
            </div>
        </div>
    </div>

    <div class="col">
        <div class="card h-100">
            <div class="card-body d-flex flex-column justify-content-between">
                <h5 class="card-title" i18n>Professionnels</h5>
                <p class="card-text fs-sm" i18n>Power-up by having automatic statistics.</p>
                <div>
                    @if (!planJustChosen && chosenPlan?.id === plans[1].id) {
                        <button class="btn btn-sm btn-primary" disabled i18n>
                            Sélectionné
                        </button>
                    } @else if (planJustChosen && chosenPlan?.id === plans[1].id) {
                        <button type="button" class="btn btn-sm btn-primary" disabled>
                            <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true">
                                <span class="visually-hidden">Saving...</span>
                            </span>
                            <ng-container i18n>Saving...</ng-container>
                        </button>
                    } @else {
                        <button class="btn btn-sm btn-outline-primary" data-cy="choose-plan"
                                i18n [disabled]="hasAlreadyPaid" (click)="choosePlan(plans[1])">
                            Choose
                        </button>
                    }
                </div>
            </div>
        </div>
    </div>
</div>

@if (hasAlreadyPaid) {
    <div class="alert d-flex alert-info" role="alert">
        <i class="bx bx-info-circle lead me-3"></i>
        <div>
            <ng-container i18n>To change or cancel your plan, please contact us at</ng-container>
            <a class="ms-1" href="mailto:contact@plan-timer.com">contact&#64;plan-timer.com</a>
        </div>
    </div>
}

@if (planJustChosen) {
    <div class="alert d-flex alert-warning mt-4" role="alert">
        <i class="bx bx-error lead me-3"></i>
        <div i18n>
            The plan has been selected and can take up to <strong>10 seconds</strong> to be activated.<br>
            Please wait ...
        </div>
    </div>
}
