
export class FinishedDay {
    constructor(
        public readonly date: Date,
        public readonly duration: number,
        public readonly time_spent: number,
    ) {
    }

    durationToMinutes(): number {
        return Math.round(this.duration / 60);
    }

    timeSpentToMinutes(): number {
        return Math.round(this.time_spent / 60);
    }
}
