import {CanActivateChildFn, CanActivateFn, Router, Routes, UrlTree} from "@angular/router";
import {inject} from "@angular/core";
import {authGuardFn, AuthService} from "@auth0/auth0-angular";
import {UserType} from "@enums/user-type.enum";
import {UserService} from "@services/user.service";
import {Client} from "@models/client";
import {PresentationComponent} from "./front/pages/presentation/presentation.component";
import {FrontComponent} from "./front/front.component";
import {ClientComponent} from "./front/pages/client/client.component";
import {EventListComponent} from "./front/pages/client/event-list/event-list.component";
import {LibraryComponent as EventLibraryComponent} from "./front/pages/client/library/library.component";
import {ProfileComponent} from "./front/pages/client/profile/profile.component";
import {SettingsComponent} from "./front/pages/client/settings/settings.component";
import {EventEditComponent} from "./front/pages/event-edit/event-edit.component";
import {EventFormComponent} from "./front/pages/event-edit/event-form/event-form.component";
import {ActionFormComponent} from "./front/pages/event-edit/action-list/action-form/action-form.component";
import {PrizeFormComponent} from "./front/pages/event-edit/prize-list/prize-form/prize-form.component";
import {ActionListComponent} from "./front/pages/event-edit/action-list/action-list.component";
import {
    LibraryComponent as ActionLibraryComponent
} from "./front/pages/event-edit/action-list/library/library.component";
import {PrizeListComponent} from "./front/pages/event-edit/prize-list/prize-list.component";
import {LibraryComponent as PrizeLibraryComponent} from "./front/pages/event-edit/prize-list/library/library.component";
import {PersonalComponent} from "./account/pages/personal/personal.component";
import {ProfileComponent as AccountProfileComponent} from "./account/pages/personal/profile/profile.component";
import {NewClientComponent} from "./account/pages/personal/new-client/new-client.component";
import {StatisticsComponent} from "./statistics/statistics.component";
import {decodeJwt} from "jose";
import {PlanComponent} from "./account/pages/personal/plan/plan.component";
import {CreateSupervisorService} from "@services/create-supervisor.service";
import {map} from "rxjs";

const isUser: CanActivateFn | CanActivateChildFn = () => {
    const authService = inject(AuthService);

    return new Promise<boolean | UrlTree>((r) => {
        authService.user$.subscribe((user) => {
            r(user['https://plan-timer.com/roles'].includes(UserType.SUPERVISOR));
        });
    });
};

const canAccessStatistics: CanActivateFn | CanActivateChildFn = () => {
    const authService = inject(AuthService);

    return new Promise<boolean | UrlTree>((r) => {
        authService.getAccessTokenSilently().subscribe((token) => {
            if (token) {
                const decodedToken = <{ permissions: string[] }>decodeJwt(token);
                r(decodedToken.permissions.includes('read:statistics'));
                return;
            }

            r(false);
        });
    });
};

const hasClients: CanActivateFn | CanActivateChildFn = () => {
    return new Promise<boolean | UrlTree>((r) => {
        const userService = inject(UserService);
        const router = inject(Router);

        userService.getClients().subscribe((clients: Client[] | null) => {
            if (!clients?.length) {
                router.navigate(['/', 'account', 'profile']);
            }

            return r(clients?.length > 0);
        });
    });
};

const canCreateClient: CanActivateFn | CanActivateChildFn = () => {
    return inject(CreateSupervisorService).hasPlan.pipe(map((canAccessClientForm) => !!canAccessClientForm));
}

const canChoosePlan: CanActivateFn | CanActivateChildFn = () => {
    const createSupervisor = inject(CreateSupervisorService);

    return new Promise<boolean>(async (r) => {
        createSupervisor.isUserSignedUp.subscribe((canAccessPlanForm) => {
            if (canAccessPlanForm) {
                r(true);
            }
        });
    });
};

const hasActiveSubscription: CanActivateFn | CanActivateChildFn = () => {
    const authService = inject(AuthService);
    const router = inject(Router);

    return new Promise<boolean | UrlTree>((r) => {
        authService.user$.subscribe(async (user) => {
            if (!user) {
                router.navigate(['/', 'account', 'plans']);
                return;
            }

            if (user['https://plan-timer.com/app_metadata']['subscription_id']) {
                r(true);
                return;
            }

            const endTrial = new Date(user['https://plan-timer.com/app_metadata']['start_trial']);
            endTrial.setDate(endTrial.getDate() + 30);
            if (new Date() < endTrial) {
                r(true);
                return;
            }

            router.navigate(['/', 'account', 'plans']);
        });
    });
};

export const routes: Routes = [
    {pathMatch: 'full', path: '', redirectTo: 'presentation'},
    {
        path: '',
        component: FrontComponent,
        canActivateChild: [authGuardFn, hasActiveSubscription, isUser, hasClients],
        children: [
            {pathMatch: 'full', path: 'client', redirectTo: 'client/events'},
            {
                path: 'presentation',
                component: PresentationComponent,
            },
            {
                path: 'client',
                component: ClientComponent,
                children: [
                    {
                        path: 'events',
                        component: EventListComponent,
                        children: [
                            {path: 'library', component: EventLibraryComponent},
                        ]
                    },
                    {path: 'profile', component: ProfileComponent},
                    {path: 'settings', component: SettingsComponent},
                ]
            },
            {
                path: 'events',
                component: EventEditComponent,
                children: [
                    {path: 'new', component: EventFormComponent},
                ]
            },
            {
                path: 'events/:eventId',
                component: EventEditComponent,
                children: [
                    {path: 'edit', component: EventFormComponent},
                    {path: 'actions/new', component: ActionFormComponent},
                    {path: 'actions/:actionId/edit', component: ActionFormComponent},
                    {path: 'prizes/new', component: PrizeFormComponent},
                    {
                        path: 'actions',
                        component: ActionListComponent,
                        children: [
                            {path: 'library', component: ActionLibraryComponent},
                        ]
                    },
                    {path: 'prizes/:prizeId/edit', component: PrizeFormComponent},
                    {
                        path: 'prizes',
                        component: PrizeListComponent,
                        children: [
                            {path: 'library', component: PrizeLibraryComponent},
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: 'account',
        component: PersonalComponent,
        canActivateChild: [authGuardFn],
        children: [
            {path: 'profile', component: AccountProfileComponent},
            {path: 'plans', component: PlanComponent, canActivate: [canChoosePlan]},
            {path: 'client/new', component: NewClientComponent, canActivate: [canCreateClient]}
        ]
    },
    {
        path: 'statistics',
        component: StatisticsComponent,
        canActivate: [authGuardFn, hasActiveSubscription, isUser, hasClients, canAccessStatistics],
        canActivateChild: [authGuardFn, hasActiveSubscription, isUser, hasClients, canAccessStatistics],
    }
];
