import {Component, inject} from '@angular/core';
import {AuthService, User} from "@auth0/auth0-angular";
import {ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {NgClass} from '@angular/common';
import {HeaderComponent} from '../../../shared/components/header/header.component';
import {CreateSupervisorService} from "@services/create-supervisor.service";
import {PaymentService} from '@services/payment.service';
import {LoadingService} from "@services/loading.service";

@Component({
    selector: 'app-personal',
    templateUrl: './personal.component.html',
    standalone: true,
    imports: [HeaderComponent, RouterLink, RouterLinkActive, NgClass, RouterOutlet]
})
export class PersonalComponent {
    user?: User;
    canChoosePlan = false;
    canCreateClient = false;

    paying = false;

    constructor() {
        const route = inject(ActivatedRoute);
        const router = inject(Router);
        const authService = inject(AuthService);
        const supervisorService = inject(CreateSupervisorService);
        const paymentService = inject(PaymentService);

        authService.user$.subscribe(user => (this.user = user));

        supervisorService.isUserSignedUp.subscribe((isSignedUp) => {
            if (isSignedUp) {
                this.canChoosePlan = isSignedUp;
            }
        });

        LoadingService.loading$.next(false);

        supervisorService.hasPlan.subscribe((hasPlan) => {
            supervisorService.hasTrial.subscribe((hasTrial) => {
                if (hasTrial) {
                    this.canCreateClient = hasPlan && hasTrial;
                    return;
                }

                supervisorService.hasPaid.subscribe((hasPayed) => {
                    this.canCreateClient = hasPlan && hasPayed;
                })
            });
        });

        if (route.snapshot.queryParams['payment'] === 'success') {
            paymentService.isPaying().subscribe((isPaying) => this.paying = isPaying);
            paymentService.setAsPaying();

            supervisorService.waitForSubscription().then((payed) => {
                if (!payed) {
                    return;
                }

                this.canCreateClient = true;
                paymentService.finishedPaying();
                router.navigate([]);
            })
        }
    }
}
