import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import {Resource} from "@models/resource";
import {ResourceScope} from "@enums/resource-scope.enum";
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-resource-form',
    templateUrl: './resource-form.component.html',
    styleUrls: ['./resource-form.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgClass, ImageCropperModule, NgxFileDropModule]
})
export class ResourceFormComponent {
    descriptionControl = new FormControl('', [Validators.required]);
    croppedImage?: Blob;
    imageToCrop: File;
    disableSaveButton = false;

    @Input() set saving(value: boolean) {
        if (value) {
            this.disableSaveButton = true;
            return;
        }

        this.disableSaveButton = false;
        this.descriptionControl.reset();
        this.imageToCrop = null;
        this.croppedImage = null;
    }

    @Output() resourceChange = new EventEmitter<Resource>();

    dropped(files: NgxFileDropEntry[]) {
        const droppedFile = files[0];

        if (droppedFile.fileEntry.isFile) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                this.croppedImage = file;
            });
        }
    }

    fileChangeEvent(event: Event): void {
        this.croppedImage = null;
        this.imageToCrop = event.target['files'][0];
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.blob;
    }

    save() {
        this.descriptionControl.markAsDirty();
        if (!(this.croppedImage && this.descriptionControl.valid)) {
            return;
        }

        const resource = new Resource(
            null,
            this.descriptionControl.value,
            ResourceScope.PRIVATE,
            this.croppedImage
        );

        this.resourceChange.emit(resource);
    }
}
