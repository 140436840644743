export class EventNotificationTime {
    constructor(private readonly _hour: number, private readonly _minute: number) {
    }

    get hours(): number {
        return this._hour;
    }

    get minutes(): number {
        return this._minute;
    }

    static fromString(time: string | null) {
        if (time === null) {
            return undefined;
        }

        const splitTime = time.split(':');

        return new EventNotificationTime(Number(splitTime[0]), Number(splitTime[1]));
    }

    hasTime(): boolean {
        return this._hour !== null && this._minute !== null;
    }

    toString(): string {
        return `${String(this.hours).padStart(2, '0')}:${String(this.minutes).padStart(2, '0')}`;
    }
}
