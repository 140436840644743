import {ActionResponse} from "@services/action.service";
import {Action} from "@models/action";
import {Timer} from "@models/timer";
import {TimerType} from "@enums/timer-type.enum";

export class ActionMapper {
    static makeFromResponse(response: ActionResponse): Action {
        const timer = response.timer ? new Timer(<TimerType>response.timer.type, response.timer.time) : null;
        return new Action(response.id, response.title, response.description, timer, response.tokens, null);
    }
}
