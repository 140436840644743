<div class="d-flex flex-row">
    @for (index of amountOfCards; track index) {
        <div class="card app-card m-2 loading-card">
            <div class="card-header py-2">
                <p class="m-0 opacity-0">Loading ...</p>
            </div>

            <span class="card-image">
                <img [src]="" alt='' class="card-img-bottom">
            </span>
        </div>
    }
</div>
