@if (event) {
    <div class="container row h-100 m-auto pt-5">
        <aside class="col-3">
            <div class="position-sticky" data-cy="event-side-menu">
                <div class="text-center">
                    <img [src]="event.resource.Link" class="rounded-circle" width="120" alt="John Doe">
                </div>
                <h2 class="h5 mt-2 mb-3 text-center">{{ event.title }}</h2>
                <div class="list-group list-group-flush collapse d-md-block">
                    <a [routerLink]="event.id ? ['/', 'events', event.id, 'edit'] : ['/', 'events', 'new']"
                       [routerLinkActive]="['active']"
                       class="list-group-item list-group-item-action d-flex align-items-center">
                        <i class="bx bx-calendar-event fs-xl opacity-60 me-2"></i>
                        <ng-container i18n>Event</ng-container>
                    </a>
                    <a [routerLink]="event.id ? ['/', 'events', event.id, 'actions'] : null"
                       [routerLinkActive]="event.id ? ['active'] : []"
                       [ngClass]="{'text-black-50': !event.id}"
                       class="list-group-item list-group-item-action d-flex align-items-center">
                        <i class="bx bx-task fs-xl opacity-60 me-2"></i>
                        <ng-container i18n>Actions</ng-container>
                        @if (event.id) {
                            <span class="badge rounded-pill bg-secondary ms-2">{{ amountOfActions }}</span>
                        }
                    </a>
                    <a [routerLink]="event.id ? ['/', 'events', event.id, 'prizes'] : null"
                       [routerLinkActive]="event.id ? ['active'] : []"
                       [ngClass]="{'text-black-50': !event.id}"
                       class="list-group-item list-group-item-action d-flex align-items-center">
                        <i class="bx bxs-balloon fs-xl opacity-60 me-2"></i>
                        <ng-container i18n>Prizes</ng-container>
                        @if (event.id) {
                            <span class="badge rounded-pill bg-secondary ms-2">{{ amountOfPrizes }}</span>
                        }
                    </a>
                </div>
            </div>
        </aside>

        <section class="col-9 p-0 col">
            <div class="col-10 mx-auto">
                <router-outlet></router-outlet>
            </div>
        </section>
    </div>
} @else {
    <app-loading-page></app-loading-page>
}
