export class VoiceRecording {
    constructor(
        private _id: number | null,
        private _soundData: Blob
    ) {
    }

    get id(): number | null {
        return this._id;
    }

    set id(value: number | null) {
        this._id = value;
    }

    get soundData(): Blob {
        return this._soundData;
    }
}
