import {Injectable} from '@angular/core';
import {ResourceService} from './resource.service';
import {BehaviorSubject, combineLatest, map, mergeMap, Observable, Subject, tap} from 'rxjs';
import {UserService} from './user.service';
import {EventNotificationTime} from "@models/event-notification-time";
import {EventRepository, EventStatsResponse} from "@repositories/event-repository";
import {Event} from "@models/event";

export interface EventResponse {
    id: number;
    title: string;
    resource_id: number;
    user_id: string;
    voice_recording_id: number;
    notification: { hours: number, minutes: number } | null;
}

@Injectable({
    providedIn: 'root'
})
export class EventService {
    editEvent$: BehaviorSubject<Event> = new BehaviorSubject(null);
    eventUpdated$ = new Subject<void>();

    constructor(
        private repository: EventRepository,
        private resourceService: ResourceService,
        private userService: UserService) {
    }

    allEventsFromClientId(clientId: string): Observable<Event[]> {
        return this.repository.getFromClient(clientId).pipe(mergeMap((eventResponses: EventResponse[]) => {
            return combineLatest(eventResponses.map((eventResponse: EventResponse) => this.mapEventResponseToEvent(eventResponse)));
        }));
    }

    get(eventId: number): Observable<Event> {
        return this.repository.get(eventId).pipe(mergeMap((response: EventResponse) => this.mapEventResponseToEvent(response)));
    }

    save(event: Event, clientId?: string): Observable<Event> {
        if (event.id) {
            return this.repository.update(event).pipe(
                mergeMap((response: EventResponse) => this.mapEventResponseToEvent(response)),
                tap(() => this.eventUpdated$.next())
            );
        }

        return this.repository.add(event, clientId).pipe(mergeMap((response: EventResponse) => this.mapEventResponseToEvent(response)));
    }

    getClientEventList(clientId: string, date: Date): Observable<Event[]> {
        return this.repository.getClientEventList(clientId, date).pipe(mergeMap((eventResponses: EventResponse[]) => {
            // Empty responses don't trigger combineLatest, so we need to return a BehaviorSubject
            if (eventResponses.length === 0) {
                return new BehaviorSubject([]);
            }

            return combineLatest(eventResponses.map((eventResponse: EventResponse) => this.mapEventResponseToEvent(eventResponse)));
        }));
    }

    saveClientList(events: Event[], clientId: string, date: Date): Observable<boolean> {
        return this.repository.saveClientList(events, clientId, date);
    }

    delete(eventId: number): Observable<boolean> {
        return this.repository.delete(eventId);
    }

    getStats(eventId: number): Observable<EventStatsResponse> {
        return this.repository.getStats(eventId);
    }

    private mapEventResponseToEvent(response: EventResponse): Observable<Event> {
        const resource$ = this.resourceService.getResourceFromResourceId(response.resource_id);
        const voiceRecording$ = this.resourceService.getVoiceRecording(response.voice_recording_id);
        const user$ = this.userService.getUser(response.user_id);

        return combineLatest([resource$, voiceRecording$, user$]).pipe(map(([resource, voiceRecording, user]) => {
            const event: Event = new Event(response.id, response.title, null);
            event.startNotificationTime = response.notification ? new EventNotificationTime(response.notification.hours, response.notification.minutes) : null;
            event.resource = resource;
            event.voiceRecording = voiceRecording;
            event.author = user;

            return event;
        }));
    }
}
