import {Injectable} from '@angular/core';
import {VoiceRecording} from '@models/voice-recording';
import {Client} from "@models/client";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

export interface VoiceRecordingResponse {
    id: number;
    user_id: string;
    client_id: string;
}

@Injectable({
    providedIn: 'root'
})
export class VoiceRecordingRepository {
    constructor(private http: HttpClient) {
    }

    getInfos(voiceRecordingId: number): Observable<VoiceRecordingResponse> {
        return this.http.get<VoiceRecordingResponse>(`/voice-recordings/${voiceRecordingId}`);
    }

    getFile(voiceRecordingId: number): Observable<Blob> {
        return this.http.get(`/voice-recordings/${voiceRecordingId}/file`, {responseType : 'blob'});
    }

    save(voiceRecording: VoiceRecording, client: Client): Observable<VoiceRecordingResponse> {
        const formData = new FormData();
        formData.append('voice_recording', voiceRecording.soundData);
        formData.append('client_id', client.id);

        return this.http.post<VoiceRecordingResponse>('/voice-recordings', formData);
    }

    delete(recordingId: number): Observable<void> {
        return this.http.delete<void>(`/voice-recordings/${recordingId}`);
    }
}
