<app-header></app-header>

@if (user) {
    <div class="container row h-100 custom-height m-auto pt-5">
        <aside class="col-3">
            <div class="position-sticky">
                <div class="text-center">
                    @if (user.gender === 'f') {
                        <img src="assets/avatarWomen.svg" width="60">
                    }
                    @if (user.gender === 'm') {
                        <img src="assets/avatarMan.svg" width="60">
                    }
                </div>
                <h2 class="h5 mt-2 mb-3 text-center">{{ user.given_name }} {{ user.family_name }}</h2>
                <div id="account-menu" class="list-group list-group-flush collapse d-md-block">
                    <a [routerLink]="['/', 'account', 'profile']" [routerLinkActive]="['active']"
                       class="list-group-item list-group-item-action d-flex align-items-center">
                        <i class="bx bx-user-circle fs-xl opacity-60 me-2"></i>
                        <ng-container i18n>Profile</ng-container>
                    </a>
                    <a [routerLink]="['/', 'account', 'plans']" [routerLinkActive]="canChoosePlan ? ['active'] : []"
                       [ngClass]="{'text-black-50': !canChoosePlan}"
                       class="list-group-item list-group-item-action d-flex align-items-center">
                        <i class="bx bx-credit-card fs-xl opacity-60 me-2"></i>
                        <ng-container i18n>Plan</ng-container>
                    </a>
                    <a [routerLink]="canCreateClient ? ['client', 'new'] : null"
                       [routerLinkActive]="canCreateClient ? ['active'] : []"
                       [ngClass]="{'text-black-50': !canCreateClient}"
                       class="list-group-item list-group-item-action d-flex align-items-center">
                        <i class="bx bx-user-plus fs-xl opacity-60 me-2"></i>
                        <ng-container i18n>New client</ng-container>
                    </a>
                </div>
            </div>
        </aside>
        <section class="col-9">
            <div class="col-10 offset-1">
                @if (paying) {
                    <div class="d-flex alert alert-primary">
                        <i class="bx bx-credit-card lead me-3"></i>
                        Your subscription is being processed. Please wait until this message disappears.
                    </div>
                }
                <router-outlet></router-outlet>
            </div>
        </section>
    </div>
}
