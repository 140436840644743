import {AmountActions} from "./amount-actions";

export class AmountActionsPerEvent {
    constructor(
        public readonly eventId: number,
        public readonly title: string,
        public readonly amountActions: AmountActions[],
    ) {
    }
}
