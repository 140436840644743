import {Component} from '@angular/core';
import {UserService} from "@services/user.service";
import {PlanningService} from "@services/planning.service";
import {PlanningConfiguration} from "@models/planning-configuration";
import {Client} from "@models/client";
import {ToastService} from "@services/toast.service";
import {SaveButtonComponent} from '../../../../shared/components/save-button/save-button.component';
import {FormsModule} from '@angular/forms';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    standalone: true,
    imports: [FormsModule, SaveButtonComponent]
})
export class SettingsComponent {
    private readonly client: Client;
    saving = false;
    name?: string;

    tokens?: boolean;
    // congratulations?: boolean;
    cheatExtinction?: boolean;
    voiceRecording?: boolean;

    constructor(private userService: UserService, private planningService: PlanningService, private toast: ToastService) {
        this.client = this.userService.currentClient.value;
        this.name = this.client.name;

        this.planningService.getConfiguration(this.client.id).then((config) => {
            this.tokens = config.TokensEnabled;
            // this.congratulations = config.CongratulationsPage;
            this.cheatExtinction = config.CheatExtinction;
            this.voiceRecording = config.VocalAssistant;
        });
    }

    async save() {
        this.saving = true;
        const saveConfiguration = new PlanningConfiguration(
            this.userService.currentClient.value.id,
            this.tokens ?? false,
            // this.congratulations ?? false,
            true,
            this.cheatExtinction ?? false,
            this.voiceRecording ?? false,
            false,
            ''
        );

        this.client.name = this.name;
        await Promise.all([
            this.planningService.saveConfiguration(saveConfiguration),
            this.userService.saveClient(this.client),
        ]);

        this.toast.success($localize`Settings saved`);

        this.saving = false;
    }
}
