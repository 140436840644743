export const environment = {
    production: false,
    base_url: 'https://stage.plan-timer.com',
    api_url: 'https://api-stage.plan-timer.com',
    auth0_domain: 'plan-timer-stage.eu.auth0.com',
    auth0_client_id: 'm4lIIjiZTSwG18sLBU3WA0Ud1akpx9i4',
    auth0_audience: 'https://api.plan-timer.com',
    auth0_redirect_uri: 'https://stage.plan-timer.com/',
    help_center_tutorials_uri: 'https://aide.plan-timer.com/tutoriels'
};
