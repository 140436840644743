<header class="navbar navbar-expand-lg bg-light shadow-sm">
    <div class="container">
        <a class="navbar-brand" routerLink="/presentation">
            <img class="d-none d-md-inline" src="assets/PlanTimer-logo.png" style="height: 60px !important;">
            <img class="d-inline d-md-none" src="assets/PlanTimer-white-logo.png" style="height: 60px !important;">
        </a>
        <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse5"
                aria-expanded="false">
            <span class="navbar-toggler-icon"></span>
        </button>

        <nav id="navbarCollapse5" class="collapse navbar-collapse d-flex justify-content-between">
            @if (clients?.length > 0) {
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/presentation" routerLinkActive="active">
                            <ng-container i18n>Dashboard</ng-container>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/client" routerLinkActive="active" i18n>Planning</a>
                    </li>
                    @if (statisticsAccess) {
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/statistics" routerLinkActive="active">
                                <ng-container i18n>Statistics</ng-container>
                                <sup><span class="badge bg-warning rounded-pill ms-1">Beta</span></sup>
                            </a>
                        </li>
                    }
                </ul>

                @if (clients?.length > 1) {
                    <div class="dropdown">
                        <button class="btn btn-outline-primary" data-bs-toggle="dropdown">
                            {{ currentClientName }}
                        </button>
                        <ul class="dropdown-menu my-1" style="width: 14rem;">
                            @for (client of clients; track client.id) {
                                <li>
                                    <a class="dropdown-item d-flex align-items-center" (click)="chooseClient(client)">
                                        {{ client.name }}
                                    </a>
                                </li>
                            }
                        </ul>
                    </div>
                } @else {
                    <button class="btn btn-outline-primary" routerLink="/client/events">{{ currentClientName }}</button>
                }
            } @else {
                <div></div>
            }

            <div class="nav-item dropdown ms-4">
                <a href="#" class="d-flex nav-link d-block p-0" data-bs-toggle="dropdown">
                    @if (currentUser?.gender === 'f') {
                        <img src="assets/avatarWomen.svg" width="48" alt="Avatar">
                    }
                    @if (!currentUser || currentUser?.gender === 'm') {
                        <img src="assets/avatarMan.svg" width="48">
                    }
                    <div class="d-none d-sm-block ps-2">
                        <div class="fs-xs lh-1 opacity-60" i18n>Hello,</div>
                        <div class="fs-sm dropdown-toggle">{{ currentUser?.firstName }}</div>
                    </div>
                </a>
                <ul class="dropdown-menu dropdown-menu-end my-1" style="width: 14rem;">
                    <li>
                        <a class="dropdown-item d-flex align-items-center"
                           [routerLink]="['/', 'account', 'profile']">
                            <i class="bx bx-profile fs-base opacity-60 me-2"></i>
                            <ng-container i18n>Profile</ng-container>
                        </a>
                    </li>
                    <li class="dropdown-divider"></li>
                    <li>
                        <a class="dropdown-item d-flex align-items-center" (click)="logout()">
                            <i class="bx bx-log-out fs-base opacity-60 me-2"></i>
                            <ng-container i18n>Sign out</ng-container>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>
