<h4 i18n>New client</h4>
<p><em i18n>A client represents a person that you are (or will be) providing help.</em></p>

<div class="alert d-flex alert-info" role="alert">
  <i class="bx bx-info-circle lead me-3"></i>
  <div>
    <ng-container i18n>Concerned about your client's privacy? You can visit our </ng-container>
    <a href="https://plan-timer.com/politique-confidentialite" class="alert-link" target="_blank" i18n>privacy policy</a>.
  </div>
</div>

<form [formGroup]="form" class="needs-validation" novalidate>
  <div class="row pb-2">
    <div class="col-sm-6 mb-4">
      <label for="fn" class="form-label fs-base" i18n>First name</label>
      <input id="fn" class="form-control form-control-lg" formControlName="first_name" required
        [appValidatedControl]="validatedForm">
      <div class="invalid-feedback" i18n>Please enter your first name!</div>
    </div>

    <div class="col-sm-6 mb-4">
      <label for="sn" class="form-label fs-base" i18n>Last name</label>
      <input id="sn" class="form-control form-control-lg" formControlName="last_name" required
        [appValidatedControl]="validatedForm">
      <div class="invalid-feedback" i18n>Please enter your last name!</div>
    </div>

    <div class="col-sm-6 mb-4">
      <label for="birthYear" class="form-label fs-base" i18n>Year of birth</label>
      <input type="number" id="birthYear" class="form-control form-control-lg" formControlName="birth_year"
        pattern="[0-9]{4}" min="1900" [max]="currentYear" required [appValidatedControl]="validatedForm">
      <div class="invalid-feedback">
        <ng-container i18n> Please provide a valid year of birth</ng-container>
      </div>
    </div>

    <div class="col-sm-6 mb-4 d-flex flex-column">
      <label for="genderFemale" class="form-label fs-base" i18n>Gender</label>
      <div class="flex-grow-1 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="genderFemale" value="f" formControlName="gender"
            required
            [ngClass]="{'is-valid': validatedForm && form.get('gender').valid, 'is-invalid': validatedForm && form.get('gender').invalid}">
          <label class="form-check-label" for="genderFemale" i18n>Female</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="genderMale" value="m" formControlName="gender"
            required
            [ngClass]="{'is-valid': validatedForm && form.get('gender').valid, 'is-invalid': validatedForm && form.get('gender').invalid}">
          <label class="form-check-label" for="genderMale" i18n>Male</label>
        </div>
      </div>
    </div>

    <p><em i18n>These are the credentials that will be used to connect your client on his device.</em></p>
    <div class="row pb-2">
      <div class="col-sm-6 mb-4">
        <label for="username" class="form-label fs-base" i18n>Username</label>
        <input id="username" class="form-control form-control-lg"
          [appValidatedControl]="validatedForm" formControlName="username" required>
        @if (form.get('username').errors?.['required']) {
          <div class="invalid-feedback" i18n>
                    The username is required
                </div>
        }
        @if (form.get('username').errors?.['usernameTaken']) {
          <div class="invalid-feedback" i18N>
            This username is already taken
          </div>
        }
      </div>

      <div class="col-sm-6 mb-4">
        <label for="pwd" class="form-label fs-base" i18n>Password</label>
        <input id="pwd" class="form-control form-control-lg" formControlName="password" minlength="8" required
          [appValidatedControl]="validatedForm">
        <div class="invalid-feedback" i18n>The password must be at least 8 characters long</div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column align-items-end">
    @if (!saving) {
      <button type="submit" class="btn btn-primary" (click)="save()" i18n>
            Save
        </button>
    }
    @if (saving) {
      <button type="button" class="btn btn-primary pe-none">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
          <span class="visually-hidden">Saving...</span>
        </span>
        <ng-container i18n>Saving...</ng-container>
      </button>
    }
  </div>
</form>
