<h4>Profile</h4>

<form [formGroup]="formProfile" class="needs-validation" [ngClass]="{'was-validated': profileValidated}" novalidate>
    <div class="row pb-2">
        <div class="col-sm-6 mb-4">
            <label for="fn" class="form-label fs-base" i18n>First name</label>
            <input id="fn" class="form-control form-control-lg" formControlName="first_name" required>
            <div class="invalid-feedback" i18n>Please enter your client's first name!</div>
        </div>

        <div class="col-sm-6 mb-4">
            <label for="sn" class="form-label fs-base" i18n>Last name</label>
            <input id="sn" class="form-control form-control-lg" formControlName="last_name" required>
            <div class="invalid-feedback" i18n>Please enter your client's last name!</div>
        </div>

        <div class="col-sm-6 mb-4">
            <label for="birthYear" class="form-label fs-base" i18n>Year of birth</label>
            <input type="number" id="birthYear" class="form-control form-control-lg" formControlName="birth_year" pattern="[0-9]{4}" min="1900" [max]="currentYear" required>
            <div class="invalid-feedback" i18n>Please provide a valid year of birth!</div>
        </div>

        <div class="col-sm-6 mb-4 d-flex flex-column">
            <label for="genderFemale" class="form-label fs-base" i18n>Gender</label>
            <div class="flex-grow-1 d-flex align-items-center">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="genderFemale" value="f" formControlName="gender" required>
                    <label class="form-check-label" for="genderFemale" i18n>Female</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="genderMale" value="m" formControlName="gender" required>
                    <label class="form-check-label" for="genderMale" i18n>Male</label>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column align-items-end">
        <button type="submit" class="btn btn-primary" [disabled]="profileSaving" (click)="saveProfile()" i18n>Save</button>
    </div>
</form>

<hr class="mt-3 mb-5">

<form [formGroup]="formPassword" class="needs-validation" [ngClass]="{'was-validated': passwordValidated}">
    <div class="row pb-2">
        <div class="col-sm-6 mb-4">
            <label for="username" class="form-label fs-base" i18n>Username</label>
            <input id="username" class="form-control form-control-lg" formControlName="username">
        </div>

        <div class="col-sm-6 mb-4">
            <label for="password" class="form-label fs-base" i18n>Password</label>
            <input type="password" id="password" class="form-control form-control-lg" formControlName="password" required minlength="8">
            <div class="invalid-feedback" i18n>The password must be at least 8 characters long</div>
        </div>
    </div>

    <div class="d-flex flex-column align-items-end mb-3">
        <button type="submit" class="btn btn-primary" (click)="savePassword()" [disabled]="passwordSaving" i18n>Save</button>
    </div>
</form>
