@if (currentUser) {
    <main class="container">
        <h1 [ngClass]="helloClass" class="hello my-5" i18n>
            Hello {{ currentUser.firstName }}.
        </h1>

        <div class="d-flex flex-wrap">
            @for (client of clients; track client.id) {
                <div style="width: 33%">
                    <div class="h-100 m-3 p-4 card shadow border-0">
                        <div class="row">
                            <div class="col-3 align-content-center">
                                @if (client.gender === 'f') {
                                    <img src="assets/avatarWomen.svg" class="w-100">
                                }
                                @if (client.gender === 'm') {
                                    <img src="assets/avatarMan.svg" class="w-100">
                                }
                            </div>
                            <div class="col align-content-center">
                                <h3 class="m-0">{{ client.name }}</h3>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col">
                                <button class="btn btn-primary w-100" (click)="goToPlanning(client.id)" i18n>
                                    Planning
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </main>
}
