import {UserType} from '@enums/user-type.enum';

export class User {
    constructor(private _id: string, private _firstName: string, private _lastName: string, private _gender?: string, private _birth?: string, private _type?: UserType[], private _informationButtons = true) {
    }

    private _emailVerified = false;

    get emailVerified(): boolean {
        return this._emailVerified;
    }

    set emailVerified(value: boolean) {
        this._emailVerified = value;
    }

    private _email = '';

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    // get type(): UserType[] {
    //     return this._type;
    // }
    //
    // set type(value: UserType[]) {
    //     this._type = value;
    // }

    get gender(): string {
        return this._gender;
    }

    set gender(value: string) {
        this._gender = value;
    }

    get birth(): string {
        return this._birth;
    }

    // setTypeFromString(type: string) {
    //     const match = Object.entries(UserType).find(([key, value]) => value === type);
    //     if (match) {
    //         const [key] = match;
    //         this.type = UserType[key];
    //     }
    // }

    set birth(value: string) {
        this._birth = value;
    }

    get name() {
        return this._firstName + ' ' + this._lastName;
    }

    get mainRole() {
        return this._type[0];
    }

    get informationButtons(): boolean {
        return this._informationButtons;
    }

    get isAdmin() {
        return this._type.filter((type) => [UserType.ADMIN, UserType.DEVELOPER].indexOf(type) > -1).length > 0;
    }
}
