<div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel" i18n>Available prizes</h1>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>

@if (event) {
    <div class="modal-body" style="height: calc(100vh - 200px); overflow: scroll">
        <p><em i18n>You may add or remove prizes to {{ event.title }} by clicking on the cards and saving your
            selection.</em></p>
        <div class="d-flex flex-wrap">
            @for (prize of prizes; track prize) {
                <div class="card app-card m-2">
                    <div
                        class="card-header bg-warning p-0 ps-2 m-0 w-100 d-flex justify-content-between align-items-center">
                        <p class="m-0">{{ prize.title }}</p>
                        <button (click)="edit(prize)" class="btn btn-icon btn-warning rounded-circle"
                                style="transform: scale(.6)">
                            <i class='bx bx-edit'></i>
                        </button>
                    </div>
                    <div (click)="toggleSelect(prize)" style="width: 150px; height: 150px">
                        <img [src]="prize.resource.Link" alt='' class="w-100 h-100">
                        @if (isSelected(prize)) {
                            <span
                                class="position-absolute w-100 bg-primary bg-opacity-25 d-flex justify-content-center align-items-center "
                                style="height: 150px; margin-top: -150px">
                                <i class="bx bx-check-circle bx-big"></i>
                            </span>
                        }
                    </div>
                </div>
            } @empty {
                @if (prizes === undefined) {
                    <app-cards-loading></app-cards-loading>
                }
                @if (prizes?.length === 0) {
                    <p i18n>No prize available</p>
                }
            }
        </div>
    </div>

    <div class="modal-footer">
        <button #cancelBtn type="button" class="btn btn-secondary" data-bs-dismiss="modal" i18n>Cancel</button>

        @if (saving) {
            <button type="button" class="btn btn-warning pe-none mx-1">
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
                    <span class="visually-hidden">Adding...</span>
                </span>
                <ng-container i18n>Adding...</ng-container>
            </button>
        } @else {
            <button type="button" class="btn btn-warning" (click)="saveToEvent()" i18n>Add to {{ event.title }}</button>
        }
    </div>
}
