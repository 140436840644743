import {Directive, DoCheck, ElementRef, Input, Renderer2} from '@angular/core';

@Directive({
    selector: '[appValidatedControl]',
    standalone: true
})
export class ValidatedControlDirective implements DoCheck {
    @Input() appValidatedControl = false;

    constructor(private element: ElementRef, private renderer: Renderer2) {
    }

    ngDoCheck(): void {
        if (!this.appValidatedControl) {
            return;
        }

        const input: HTMLInputElement = this.element.nativeElement;
        if (input.classList.contains('ng-invalid')) {
            this.renderer.addClass(input, 'is-invalid')
        }
        if (!input.classList.contains('ng-invalid')) {
            this.renderer.removeClass(input, 'is-invalid')
        }

        if (input.classList.contains('ng-valid')) {
            this.renderer.addClass(input, 'is-valid')
        }
        if (!input.classList.contains('ng-valid')) {
            this.renderer.removeClass(input, 'is-valid')
        }
    }
}
