<div class="row">
  <div class="col-6 offset-3">
    <h2 i18n>Add an image</h2>

    <div class="form-floating mb-4">
      <input id="description" class="form-control" placeholder="The image's description"
        [formControl]="descriptionControl"
        [ngClass]="{'is-invalid': descriptionControl.dirty && descriptionControl.invalid}">
      <label for="description" i18n>Description</label>
    </div>

    @if (imageToCrop) {
      <div class="d-flex justify-content-center align-content-center" data-cy="img-crop-zone">
        <div style="max-height: 300px;">
          <image-cropper (imageCropped)="imageCropped($event)" [aspectRatio]="1"
            [imageFile]="imageToCrop" [maintainAspectRatio]="true"
            format="png"
            resizeToHeight="400"
            resizeToWidth="400"
          ></image-cropper>
        </div>
      </div>
    }

    @if (!imageToCrop) {
      <div (click)="fileInput.click()" data-cy="drop-zone">
        <ngx-file-drop (onFileDrop)="dropped($event)"
          accept="image/jpeg,image/png" contentClassName="file-drop-content text-center"
          dropZoneClassName="file-drop-zone d-flex justify-content-center align-items-center">
          <ng-template let-openFileSelector="openFileSelector" ngx-file-drop-content-tmp>
            <p class="cloud-upload my-3">
              <i class='bx bx-cloud-upload'></i>
            </p>
            <p i18n>Drag your image or click here</p>
            <p>jpg, jpeg, png</p>
          </ng-template>
        </ngx-file-drop>
      </div>
    }
    <input #fileInput (change)="fileChangeEvent($event)" accept="image/jpeg,image/png" class="visually-hidden"
      type="file">

    <div class="text-end">
      @if (!disableSaveButton) {
        <button (click)="save()" type="button" class="btn btn-primary mt-4" i18n>
          Save
        </button>
      }
      @if (disableSaveButton) {
        <button type="button" class="btn btn-primary pe-none mt-4" disabled>
          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true">
            <span class="visually-hidden" i18n>Saving...</span>
          </span>
          <ng-container i18n>Saving ...</ng-container>
        </button>
      }
    </div>
  </div>
</div>
