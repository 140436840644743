import {Component} from '@angular/core';
import { CdkDragDrop, moveItemInArray, CdkDropList } from '@angular/cdk/drag-drop';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import {EventService} from '@services/event.service';
import {UserService} from "@services/user.service";
import {Client} from "@models/client";
import {Event} from "@models/event";
import {debounceTime} from "rxjs/operators";
import {Subject} from "rxjs";
import {ToastService} from '@services/toast.service';
import { CardComponent } from '@components/front/card/card.component';
import { FormsModule } from '@angular/forms';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import {CardsLoadingComponent} from "@components/front/cards-loading/cards-loading.component";
import {DatePipe, NgClass} from "@angular/common";

interface SimpleDateObject {
    year: number;
    month: number;
    day: number;
}

@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    standalone: true,
    imports: [NgbInputDatepicker, FormsModule, CdkDropList, CardComponent, RouterOutlet, CardsLoadingComponent, NgClass, DatePipe]
})
export class EventListComponent {
    client: Client;
    events: Event[];

    eventsChanged$ = new Subject<void>();
    date: SimpleDateObject = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate()
    };

    constructor(private router: Router, private route: ActivatedRoute, private userService: UserService, private eventService: EventService, private toast: ToastService) {
        // In case the user changes client, subscribe to the "reload event" because angular doesn't reload the component
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && this.router.url.includes('client/events')) {
                this.loadEvents();
            }
        });

        this.loadEvents();

        this.eventsChanged$.pipe(debounceTime(1000)).subscribe(() => this.saveEventList());
    }

    private loadEvents() {
        const date = this.simpleDateToDate(this.date);

        this.userService.currentClient.subscribe((client) => {
            this.client = client;
            this.eventService.getClientEventList(this.client.id, date).subscribe(events => this.events = events);
        });
    }

    private simpleDateToDate(simpleDate: SimpleDateObject): Date {
        const date = new Date();
        date.setFullYear(simpleDate.year);
        date.setMonth(simpleDate.month - 1);
        date.setDate(simpleDate.day);

        return date;
    }

    openLibrary() {
        const date = this.date.year + '-' +
                            String(this.date.month).padStart(2, '0') + '-' +
                            String(this.date.day).padStart(2, '0');

        this.router.navigate(['library'], {
            queryParams: {date},
            relativeTo: this.route,
            skipLocationChange: true
        });
    }

    openEventForm() {
        this.router.navigate(['/', 'events', 'new'], {relativeTo: this.route});
    }

    drop(event: CdkDragDrop<any, any>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.eventsChanged$.next();
    }

    removeEventFromList(index: number) {
        this.events.splice(index, 1);
        this.eventsChanged$.next();
    }

    saveEventList() {
        const date = this.simpleDateToDate(this.date);

        this.eventService.saveClientList(this.events, this.client.id, date).subscribe(() => {
            this.toast.success($localize`The planning has been saved`);
        });
    }

    onDateChange(event: any) {
        this.events = undefined;
        this.date = event;

        this.eventService.getClientEventList(this.client.id, this.simpleDateToDate(event)).subscribe(events => this.events = events);
    }
}
