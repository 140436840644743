@if (event && prizes) {
    <div>
        <h4 i18n>Prizes of {{ event.title }}</h4>
        <p><em i18n>You may add prizes by using the library or by creating a new prize</em></p>
        <div class="row mb-5">
            <div class="col-6">
                <div class="card text-white bg-primary h-100" style="cursor: pointer" (click)="openLibrary()"
                     data-bs-toggle="modal"
                     data-bs-target="#exampleModal">
                    <div class="card-body">
                        <h6 class="card-title text-white" i18n>Library</h6>
                        <p class="card-text fs-sm" i18n>Add from your library</p>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="card text-white bg-warning" style="cursor: pointer" (click)="openPrizeForm()">
                    <div class="card-body">
                        <h6 class="card-title text-white" i18n>New prize</h6>
                        <p class="card-text fs-sm" i18n>Create an action and add it to {{ event.title }}</p>
                    </div>
                </div>
            </div>
        </div>
        <p><em i18n>You may order the prizes by dragging the cards below to new positions</em></p>
        <div (cdkDropListDropped)="drop($event)"
             [cdkDropListData]="prizes"
             cdkDropList
             cdkDropListLockAxis="x"
             cdkDropListOrientation="horizontal"
             class="d-flex border rounded-2 h-100 overflow-scroll"
             [ngClass]="{'justify-content-center': prizes?.length === 0}"
             style="min-height: 150px">
            @for (prize of prizes; track prize; let i = $index) {
                <app-card
                    color="warning" header="Prize" i18n-header
                    [Title]="prize.title" [resource]="prize.resource"
                    (deleteCard)="removePrizeFromList(i)"></app-card>
            } @empty {
                @if (!prizes) {
                    <app-cards-loading></app-cards-loading>
                } @else {
                    <div class="d-flex flex-column p-3 align-items-center">
                        <p class="text-center" i18n>No prize in this event.</p>
                        <img src="assets/add-element.svg" alt="Add prizes to the event" i18n-alt width="200" height="200">
                        <div class="text-center">
                            <button class="btn btn-warning mt-2" (click)="openPrizeForm()" i18n>Create a prize</button>
                        </div>
                    </div>
                }
            }
        </div>
    </div>

    <div id="exampleModal" class="modal" tabindex="-1">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
} @else {
    <app-loading-page></app-loading-page>
}
