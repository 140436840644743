import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Resource} from "@models/resource";
import { CdkDrag } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.css'],
    standalone: true,
    imports: [CdkDrag]
})
export class CardComponent implements OnInit {
    // Some inputs to show in the card
    @Input() header: string;
    @Input() title: string;
    @Input() color: string; // Bootstrap colors (primary, success, danger ...)
    @Input() resource: Resource;
    @Input() width: number;
    // Events to be bound with
    @Output() editCard = new EventEmitter<void>();
    @Output() deleteCard = new EventEmitter<void>();
    @Output() singleClick = new EventEmitter<void>();
    displayEditButton = false;
    displayDeleteButton = false;

    @Input()
    set Title(title: string) {
        this.title = (title.length > 10) ? title.substring(0, 10) + '...' : title;
    }

    ngOnInit(): void {
        this.displayEditButton = (this.editCard.observers.length > 0);
        this.displayDeleteButton = (this.deleteCard.observers.length > 0);
    }
}
