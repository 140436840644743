import {ApplicationConfig} from '@angular/core';
import {provideRouter, withRouterConfig} from '@angular/router';
import {routes} from './app.routes';
import {authHttpInterceptorFn, provideAuth0} from "@auth0/auth0-angular";
import {environment} from "@environments/environment";
import {
    HttpEvent,
    HttpHandlerFn,
    HttpInterceptorFn,
    HttpRequest,
    provideHttpClient,
    withInterceptors
} from "@angular/common/http";
import {Observable} from "rxjs";
import {provideToastr} from "ngx-toastr";
import {provideCharts, withDefaultRegisterables} from "ng2-charts";
import {provideAnimations} from "@angular/platform-browser/animations";

const prefixApiInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    if (req.url.startsWith('http')) {
        return next(req);
    }

    return next(req.clone({url: environment.api_url + req.url}));
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withRouterConfig({onSameUrlNavigation: 'reload'})),
        provideAuth0({
            domain: environment.auth0_domain,
            clientId: environment.auth0_client_id,
            authorizationParams: {
                audience: environment.auth0_audience,
                redirect_uri: environment.base_url + '/presentation',
            },
            useRefreshTokens: true,
            cacheLocation: 'localstorage',
            httpInterceptor: {
                allowedList: [`${environment.api_url}/*`],
            }
        }),
        provideHttpClient(withInterceptors([prefixApiInterceptor, authHttpInterceptorFn])),
        provideToastr(),
        provideCharts(withDefaultRegisterables()),
        provideAnimations(),
    ],
};
