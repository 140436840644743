import {Component} from '@angular/core';
import {CdkDragDrop, CdkDropList, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, ParamMap, Router, RouterLink, RouterOutlet} from '@angular/router';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {ToastService} from "@services/toast.service";
import {UserService} from "@services/user.service";
import {ActionService} from "@services/action.service";
import {Action} from "@models/action";
import {Event} from "@models/event";
import {EventService} from "@services/event.service";
import {CardComponent} from '@components/front/card/card.component';
import {LoadingPageComponent} from "@components/front/loading-page/loading-page.component";
import {CardsLoadingComponent} from "@components/front/cards-loading/cards-loading.component";
import {NgClass} from "@angular/common";

@Component({
    selector: 'app-action-list',
    templateUrl: './action-list.component.html',
    styleUrls: ['./action-list.component.css'],
    standalone: true,
    imports: [RouterLink, CdkDropList, CardComponent, RouterOutlet, LoadingPageComponent, CardsLoadingComponent, NgClass]
})
export class ActionListComponent {
    event?: Event;
    actions: Action[];

    actionsChanged$ = new Subject<void>();

    constructor(private route: ActivatedRoute, private router: Router, private eventService: EventService, private actionService: ActionService, private userService: UserService, private toast: ToastService) {
        this.route.parent.paramMap.subscribe((params: ParamMap) => {
            const eventId = Number(params.get('eventId'));

            this.eventService.get(eventId).subscribe((event) => this.event = event);
            this.actionService.getList(eventId).subscribe((actions) => this.actions = actions);
        });

        this.actionsChanged$.pipe(debounceTime(1000)).subscribe(() => this.saveList());

        this.actionService.actionList$.subscribe((actions) => this.actions = actions);
    }

    dragAndDrop(event: CdkDragDrop<unknown[]>): void {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.actionsChanged$.next();
    }

    removeActionFromList(index: number): void {
        this.actions.splice(index, 1);
        this.actionsChanged$.next();
    }

    openLibrary() {
        this.router.navigate(['library'], {
            relativeTo: this.route,
            skipLocationChange: true,
            queryParams: {eventId: this.event.id}
        }).then();
    }

    private saveList(): void {
        this.actionService.saveList(this.event.id, this.actions).subscribe(() => {
            this.toast.success($localize`Action list saved`);
        });
    }
}
