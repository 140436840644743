import {Injectable} from '@angular/core';
import {ResourceService} from './resource.service';
import {BehaviorSubject, combineLatest, map, mergeMap, Observable, tap} from 'rxjs';
import {UserService} from './user.service';
import {ActionMapper} from "@mappers/action-mapper";
import {ActionRepository} from "@repositories/action-repository";
import {Client} from "@models/client";
import {Action} from "@models/action";
import {EventService} from "@services/event.service";

export interface ActionResponse {
    id: number;
    title: string;
    description: string | null;
    timer: {
        type: number;
        time: number;
    } | null;
    tokens: number;
    resource_id: number;
    voice_recording_id: number;
    user_id: string;
}

@Injectable({
    providedIn: 'root'
})
export class ActionService {
    actionList$ = new BehaviorSubject<Action[]>([]);

    constructor(
        private eventService: EventService,
        private actionRepository: ActionRepository,
        private resourceService: ResourceService,
        private userService: UserService) {
    }

    getAllFromClientId(clientId: string): Observable<Action[]> {
        return this.actionRepository.getAllFromClientId(clientId).pipe(mergeMap((response: ActionResponse[]) => {
            return combineLatest(response.map((item: ActionResponse) => this.mapActionResponseToAction(item)));
        }));
    }

    get(actionId: number): Observable<Action> {
        return this.actionRepository.get(actionId).pipe(mergeMap((response: ActionResponse) => {
            return this.mapActionResponseToAction(response);
        }));
    }

    save(action: Action, client?: Client): Observable<Action> {
        if (action.id) {
            return this.actionRepository.update(action).pipe(
                mergeMap((response: ActionResponse) => this.mapActionResponseToAction(response)),
                tap(() => this.eventService.eventUpdated$.next())
            );
        }

        return this.actionRepository.add(action, client.id).pipe(
            mergeMap((response: ActionResponse) => this.mapActionResponseToAction(response))
        );
    }

    getList(eventId: number): Observable<Action[]> {
        return this.actionRepository.getList(eventId).pipe(mergeMap((response: ActionResponse[]) => {
            if (response.length === 0) {
                return new BehaviorSubject([]);
            }

            return combineLatest(response.map((item: ActionResponse) => this.mapActionResponseToAction(item)));
        }));
    }

    saveList(eventId: number, actions: Action[]): Observable<boolean> {
        return this.actionRepository.saveList(eventId, actions).pipe(
            tap(() => this.actionList$.next(actions)),
            tap(() => this.eventService.eventUpdated$.next()),
        );
    }

    delete(actionId: number): Observable<void> {
        return this.actionRepository.delete(actionId);
    }

    private mapActionResponseToAction(actionResponse: ActionResponse): Observable<Action> {
        const resource$ = this.resourceService.getResourceFromResourceId(actionResponse.resource_id);
        const voiceRecordings$ = this.resourceService.getVoiceRecording(actionResponse.voice_recording_id);
        const user$ = this.userService.getUser(actionResponse.user_id);

        return combineLatest([resource$, voiceRecordings$, user$]).pipe(map(([resource, voiceRecording, user]) => {
            const action = ActionMapper.makeFromResponse(actionResponse);
            action.resource = resource;
            action.voiceRecording = voiceRecording;
            action.author = user;

            return action;
        }));
    }
}
