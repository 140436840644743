<div class="form-check form-switch d-flex">
    <div class="ms-2">
        <input type="checkbox" class="form-check-input" id="reminderSwitch" [checked]="active" (change)="toggleTimer()">
        <label class="form-check-label" for="reminderSwitch">
            <span class="d-block h6 mb-2" i18n>Add a timer</span>
            <span
                class="d-block mb-2" i18n>Timer in HH:MM:SS format (e.g. 01:30:45 for a timer that lasts 1 hour, 30 minutes and 45 seconds)</span>
            @for (type of availableTimerTypes; track type) {
                <span class="d-block mb-2">{{ type.hint }}</span>
            }
        </label>

        <div class="d-flex align-items-end">
            <div class="me-3">
                <label for="select-input" class="form-label" i18n>Timer type</label>
                <select class="form-select" id="select-input" [formControl]="timerTypeFormControl" (blur)="onTouched()"
                        [ngClass]="validateClassList()" data-cy="timer-type-select">
                    @for (type of availableTimerTypes; track type) {
                        <option [value]="type.value" [selected]="availableTimerTypes.length === 1">
                            {{ type.label }}
                        </option>
                    }
                </select>
            </div>


            <div id="timepicker" [ngClass]="{'validated': validatedForm}" data-cy="time-selector-container">
                <div class="d-flex">
                    <ngb-timepicker [disabled]="!active" [formControl]="timerFormControl" (click)="setDefault()"
                                    [seconds]="true" [required]="true"></ngb-timepicker>
                    <span class="is-valid-tick ms-3" [ngClass]="validateClassList()"></span>
                </div>
            </div>
        </div>

        @if (this.validatedForm && (this.timerTypeFormControl.invalid || this.timerFormControl.invalid)) {
            <div class="invalid-feedback d-block" i18n>
                Please select a type of timer and a time higher than 0.
            </div>
        }
    </div>
</div>
