import '@angular/localize/init';
import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {NgbTimepickerConfig, NgbTimepickerModule, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {EventNotificationTime} from '@models/event-notification-time';
import {FormsModule} from '@angular/forms';

@Component({
    selector: 'app-hour-selector',
    templateUrl: './hour-selector.component.html',
    standalone: true,
    imports: [NgbTimepickerModule, FormsModule]
})
export class HourSelectorComponent {
    eventNotificationTime: EventNotificationTime | null = null;
    timeStruct: NgbTimeStruct = {hour: 0, minute: 0, second: 0};
    active = false;

    @Input() set time(eventNotificationTime: EventNotificationTime | null) {
        this.eventNotificationTime = eventNotificationTime;

        if (eventNotificationTime === null) {
            this.active = false;
            return;
        }

        this.active = true;

        this.timeStruct = {
            hour: eventNotificationTime?.hours ?? 0,
            minute: eventNotificationTime?.minutes ?? 0,
            second: 0
        };
    }

    @Output() timeChange = new EventEmitter<EventNotificationTime>();

    constructor() {
        inject(NgbTimepickerConfig).spinners = false;
    }


    changeTime(value: NgbTimeStruct): void {
        this.timeStruct = value;
        this.timeChange.emit(new EventNotificationTime(value.hour, value.minute));
    }

    validate() {
        return this.active && this.eventNotificationTime?.hasTime();
    }

    toggleSelector() {
        this.active = !this.active;
        if (this.active === false) {
            this.timeChange.emit(null);
            return;
        }

        this.timeStruct = {hour: 0, minute: 0, second: 0};
        this.timeChange.emit(null);
    }
}
