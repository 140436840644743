import {AfterViewInit, Component, inject} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {UserType} from "@enums/user-type.enum";
import {User} from "@models/user";
import {NgClass} from '@angular/common';
import {HeaderComponent} from '../../../shared/components/header/header.component';
import {UserService} from "@services/user.service";
import {BaseChartDirective} from "ng2-charts";
import {Client} from "@models/client";
import {Router} from "@angular/router";

@Component({
    selector: 'app-presentation',
    templateUrl: './presentation.component.html',
    styleUrls: ['./presentation.component.css'],
    standalone: true,
    imports: [HeaderComponent, NgClass, BaseChartDirective]
})
export class PresentationComponent implements AfterViewInit {
    private userService = inject(UserService);
    private router = inject(Router);

    currentUser: User = null;
    helloClass = '';
    clients: Client[] = [];

    constructor(private authService: AuthService) {
        this.authService.user$.subscribe((data) => {
            const type = data['https://plan-timer.com/roles'][0] as UserType;
            const userInfo = data['https://plan-timer.com/user_metadata'];

            const user = new User(data.sub, data.given_name, data.family_name, userInfo.gender, userInfo.date_birth, [type]);
            user.emailVerified = data.email_verified;
            user.email = data.email;

            this.currentUser = user;
        });

        this.userService.getClients().subscribe((clients) => (this.clients = clients));
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.helloClass = 'opacity-100');
    }

    goToPlanning(id: string) {
        this.userService.setCurrentClient(this.clients.find((client) => client.id === id));
        this.router.navigate(['/', 'client', 'events']);
    }
}
