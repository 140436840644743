import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Prize} from '@models/prize';
import {TimerType} from '@enums/timer-type.enum';
import {PrizeService} from '@services/prize.service';
import {ToastService} from '@services/toast.service';
import {Event} from "@models/event";
import {EventService} from "@services/event.service";
import {combineLatest} from "rxjs";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Timer} from "@models/timer";
import {ResourceService} from "@services/resource.service";
import {VoiceRecordingComponent} from '@components/front/voice-recording/voice-recording.component';
import {TimerSelectorComponent} from '@components/front/timer-selector/timer-selector.component';
import {ResourcePickerComponent} from '@components/front/resource-picker/resource-picker.component';
import { NgClass } from '@angular/common';
import {LoadingPageComponent} from "@components/front/loading-page/loading-page.component";

@Component({
    selector: 'app-prize-form',
    templateUrl: './prize-form.component.html',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgClass, ResourcePickerComponent, TimerSelectorComponent, VoiceRecordingComponent, LoadingPageComponent]
})
export class PrizeFormComponent {
    @ViewChild('close') close: ElementRef<HTMLButtonElement>;

    event: Event;
    prize: Prize;
    saving = false;
    deleting: boolean;
    formValidated = false;

    form: FormGroup<{
        title: FormControl<string>,
        tokens: FormControl<number>,
        timer: FormControl<Timer | null>
    }> = this.fb.group({
        title: ['', Validators.required],
        tokens: [0, Validators.required],
        timer: [null],
    });

    readonly availableTimerTypes = [
        {value: TimerType.TIMETIMER, label: 'Time timer', hint: 'Minuteur: doit effectuer l\'action avant la fin.'}
    ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private eventService: EventService,
        private prizeService: PrizeService,
        private toast: ToastService,
        private fb: FormBuilder,
        private resourceService: ResourceService,
    ) {
        this.resourceService.getResourceFromResourceId(null).subscribe((resource) => {
            this.prize = new Prize(null, '', null, 0, resource);
        });

        this.route.parent.paramMap.subscribe((params: ParamMap) => {
            if (params.has('eventId')) {
                const eventId = Number(params.get('eventId'));
                this.eventService.get(eventId).subscribe((event) => this.event = event);
            }
        });

        this.route.paramMap.subscribe((params: ParamMap) => {
            if (params.has('prizeId')) {
                const prizeId = Number(params.get('prizeId'));
                this.prizeService.get(prizeId).subscribe((prize) => {
                    this.prize = prize;
                    this.form.get('title').setValue(prize.title);
                    this.form.get('tokens').setValue(prize.tokenCost);
                    this.form.get('timer').setValue(prize.timer);
                });
            }
        });
    }

    savePrize(): void {
        this.formValidated = true;
        this.form.markAsDirty();
        if (this.form.invalid || !this.prize.resource?.Id) {
            return;
        }

        this.saving = true;
        const f = this.form;
        const prize = new Prize(this.prize.id, f.get('title').value, f.get('timer').value, f.get('tokens').value, this.prize.resource);
        this.prizeService.save(prize).subscribe(() => {
            this.router.navigate(['events', this.event.id, 'prizes']).then();
            this.toast.success($localize`Prize saved`);
            this.saving = false;
        });
    }

    savePrizeAndAdd(): void {
        this.formValidated = true;
        this.form.markAsDirty();
        if (this.form.invalid || !this.prize.resource?.Id) {
            return;
        }

        this.saving = true;

        const getList$ = this.prizeService.getList(this.event.id);

        const f = this.form;
        const prize = new Prize(this.prize.id, f.get('title').value, f.get('timer').value, f.get('tokens').value, this.prize.resource);
        combineLatest([getList$, this.prizeService.save(prize)]).subscribe(([prizeList, prize]) => {
            if (prizeList.length >= 2) {
                this.toast.warn($localize`Your prize has been saved, but you can't add more than 2 prizes to an event.`);
                this.saving = false;
                this.cancel();
                return;
            }

            prizeList.push(prize);

            this.prizeService.saveList(this.event.id, prizeList).subscribe(() => {
                this.toast.success($localize`Prize saved`);
                this.saving = false;
                this.cancel();
            });
        });
    }

    cancel() {
        this.router.navigate(['events', this.event.id, 'prizes']).then();
    }

    validateClassList(controlName: string): object {
        return {
            'is-valid': this.form.get(controlName).valid && this.formValidated,
            'is-invalid': this.form.get(controlName).invalid && this.formValidated,
        };
    }

    delete() {
        this.deleting = true;
        this.prizeService.delete(this.prize.id).subscribe(() => {
            this.deleting = false;
            this.close.nativeElement.click();
            this.toast.success($localize`Prize deleted`);
            this.router.navigate(['events', this.event.id, 'prizes']).then();
        });
    }

    invalidResource() {
        return this.formValidated && !this.prize.resource?.Id;
    }
}
