<h4 i18n>Profile</h4>

@if (!signedUp) {
    <div class="alert d-flex alert-primary" role="alert">
        <i class="bx bx-info-circle lead me-3"></i>
        <div>
            <h6 class="mb-0 alert-heading" i18n>Finalise the signup</h6>
            <p class="mb-0" i18n>Just a few more details to finalise your account...</p>
        </div>
    </div>
}

<form [formGroup]="formProfile" class="needs-validation" [ngClass]="{'was-validated': profileValidated}"
      novalidate>
    <div class="row mb-4">
        <div class="col-sm-6">
            <label for="fn" class="form-label fs-base" i18n>First name</label>
            <input id="fn" class="form-control form-control-lg" formControlName="first_name" required>
            <div class="invalid-feedback" i18n>Please enter your first name</div>
        </div>

        <div class="col-sm-6">
            <label for="sn" class="form-label fs-base" i18n>Last name</label>
            <input id="sn" class="form-control form-control-lg" formControlName="last_name" required>
            <div class="invalid-feedback" i18n>Please enter your last name</div>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col-sm-6">
            <label for="birthDate" class="form-label fs-base" i18n>Date of birth</label>
            <input type="date" id="birthDate" class="form-control form-control-lg" formControlName="birth_date"
                   min="1900-01-01" [max]="currentDate" required>
            <div class="invalid-feedback" i18n>Please provide a valid date of birth</div>
        </div>

        <div class="col-sm-6 d-flex flex-column">
            <label for="genderFemale" class="form-label fs-base" i18n>Gender</label>
            <div class="flex-grow-1 d-flex align-items-center">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="genderFemale" value="f" formControlName="gender"
                           required>
                    <label class="form-check-label" for="genderFemale" i18n>Female</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="genderMale" value="m" formControlName="gender"
                           required>
                    <label class="form-check-label" for="genderMale" i18n>Male</label>
                </div>
            </div>
        </div>
    </div>

    @if (!signedUp) {
        <div class="row mb-4">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="eula" formControlName="eula" required>
                <label class="form-check-label" for="eula">
                    <ng-container i18n>By signing up to Plan Timer, you accept the</ng-container>
                    <a class="ms-1" href="https://plan-timer.com/cgu" target="_blank" i18n>End-User Licence Agreement
                        (EULA)</a>.
                </label>
            </div>
        </div>
    }

    <div class="d-flex flex-column align-items-end">
        @if (profileSaving) {
            <button class="btn btn-primary" [disabled]="profileSaving">
                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true">
                    <span class="visually-hidden">Saving...</span>
                </span>
                <ng-container i18n>Saving</ng-container>
            </button>
        } @else {
            <button type="submit" class="btn btn-primary" (click)="saveProfile()" i18n>Save</button>
        }
    </div>
</form>
