import {AbstractRepository} from './abstract-repository';
import {Injectable} from '@angular/core';
import {EventResponse} from "@services/event.service";
import {Event} from "@models/event";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

export interface EventStatsResponse {
    actions: number;
    prizes: number;
}

@Injectable({
    providedIn: 'root'
})
export class EventRepository {
    constructor(private http: HttpClient) {
    }

    private static dateToString(date: Date): string {
        const month: string = String(Number(date.getMonth()) + 1).padStart(2, '0');
        const day: string = String(date.getDate()).padStart(2, '0');

        return date.getFullYear() + '-' + month + '-' + day;
    }

    getFromClient(clientId: string): Observable<EventResponse[]> {
        return this.http.get<EventResponse[]>(`/events/client/${clientId}`);
    }

    get(eventId: number): Observable<EventResponse> {
        return this.http.get<EventResponse>(`/events/${eventId}`);
    }

    add(event: Event, clientId: string): Observable<EventResponse> {
        const body = {
            title: event.title,
            client_id: clientId,
            resource_id: event.resource.Id,
            voice_recording_id: event.voiceRecording?.id ?? null,
            notification: event.startNotificationTime ? {
                hours: event.startNotificationTime.hours,
                minutes: event.startNotificationTime.minutes
            } : null,
        };

        return this.http.post<EventResponse>('/events', body);
    }

    update(event: Event): Observable<EventResponse> {
        const body = {
            title: event.title,
            resource_id: event.resource?.Id ?? null,
            voice_recording_id: event.voiceRecording?.id ?? null,
            notification: event.startNotificationTime ? {
                hours: event.startNotificationTime.hours,
                minutes: event.startNotificationTime.minutes
            } : null,
        };

        return this.http.put<EventResponse>(`/events/${event.id}`, body);
    }

    getClientEventList(clientId: string, date: Date): Observable<EventResponse[]> {
        return this.http.get<EventResponse[]>(`/events/list/${clientId}/` + EventRepository.dateToString(date));
    }

    saveClientList(events: Event[], clientId: string, date: Date): Observable<boolean> {
        const body = {
            events: events.map((event: Event) => event.id),
            client_id: clientId,
            date: EventRepository.dateToString(date)
        };

        return this.http.post<boolean>('/events/list', body);
    }

    delete(eventId: number): Observable<boolean> {
        return this.http.delete<boolean>(`/events/${eventId}`);
    }

    getStats(eventId: number): Observable<EventStatsResponse> {
        return this.http.get<EventStatsResponse>(`/events/${eventId}/stats`);
    }
}
